import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { FC, useEffect, useRef } from "react";

import Reply from "../ReplyModal/Reply";
import { PrivateMessageCard } from "../MessageCard/privateMessageCard/PrivateMessageCard";
import { AlertWarning, ApprovalMessageCard, WarningIcon } from "@bbdevcrew/bb_ui_kit_fe";

import {
  postReplyRegionIsNotAllowedFailureSelector,
  repliedThreadCommentSelector,
  replyingThreadCommentSelector,
  updatingReplyApprovalSelector,
} from "@store/replies/selectors";

import { IMessage } from "@store/replies/types";
import { IMessageListPrivateProps } from "./MessageList.type";
import { useReplyingComment } from "@utils/comments";
import { isFacebookPlatformStrict, isTiktokPlatform } from "@bbdevcrew/bb_ui_kit_fe";
import { useReplyModalContext } from "@components/_common/ReplyModal/ReplyModalContext";

import s from "./MessageList.module.less";

const DEFAULT_DEBOUNCE_VALUE = 300;

export const MessageListPrivate: FC<IMessageListPrivateProps> = ({
  parent,
  replyData,
  isReplyBtnLoading,
  onInternalReply,
  listId,
  onApprovalAction,
}) => {
  const { setTranslateAllButtonVisible } = useReplyModalContext();
  const { t } = useTranslation();
  const bottomRef = useRef<HTMLDivElement>(null);
  const { items } = replyData;
  const comment = useReplyingComment(replyData, listId);
  const platformId = replyData.author_detail.platform_type.id;
  const isTTComment = isTiktokPlatform(platformId);
  const replyApprovalData = replyData?.reply_approval;

  const replyingThreadComment = useSelector(replyingThreadCommentSelector);
  const repliedThreadComment = useSelector(repliedThreadCommentSelector);
  const postReplyRegionIsNotAllowedFailure = useSelector(
    postReplyRegionIsNotAllowedFailureSelector,
  );
  const updatingReplyApproval = useSelector(updatingReplyApprovalSelector);

  // Keep the scroll on the bottom always with 300ms debounce
  const autoScrolldown = (ms: number) => {
    setTimeout(() => bottomRef.current?.scrollIntoView({ behavior: "smooth" }), ms);
  };

  const showTranslationButtonIfAvailable = () => {
    const suitableMessageLanguages = [
      parent.language_detected,
      parent.brand_reply?.language_detected,
      ...items.map(item => [item.language_detected, item.brand_reply?.language_detected]),
    ];

    const nonEnglishLanguagesExist = suitableMessageLanguages
      .flat()
      .filter(language => language)
      .some(language => language !== "en");

    setTranslateAllButtonVisible(nonEnglishLanguagesExist);
  };

  useEffect(() => {
    autoScrolldown(DEFAULT_DEBOUNCE_VALUE);
    showTranslationButtonIfAvailable();
    // eslint-disable-next-line
  }, [items.length]);

  const isLastMessageOfType = (currentItem: IMessage, nextItem: IMessage | null) => {
    if (nextItem === null) return true;

    return !(currentItem.owned === nextItem.owned);
  };

  const showPublicToPrivateDisclaimer = !!replyingThreadComment || !!repliedThreadComment;

  return (
    <div className={s.bbMessageListPrivate}>
      <div className={s.bbDMChat}>
        {!!items.length &&
          items?.map((item, index) => {
            const {
              id,
              owned,
              message,
              username,
              is_hidden,
              replied_as,
              brand_reply,
              attachments,
              reply_phrase,
              created_time,
              created_time_pretty,
              message_translated,
              private_replied_public_comment_id,
              tags,
              ai_tags,
              custom_tags_by_user,
              custom_tags_by_automation,
              post_type,
            } = item;

            const isLastItem = index === items.length - 1;
            const nextMessage = items[index + 1];
            const nextAfterClientMessage = brand_reply
              ? brand_reply
              : !isLastItem
                ? nextMessage
                : null;
            const nextAfterBrandReply = !isLastItem ? nextMessage : null;

            return (
              <>
                <PrivateMessageCard
                  id={id}
                  tags={tags}
                  aiTags={ai_tags}
                  isOwned={owned}
                  message={message}
                  parentId={parent.id}
                  key={`message-${id}`}
                  isHidden={is_hidden}
                  attachments={attachments}
                  createdTime={created_time}
                  createdTimePretty={created_time_pretty}
                  avatarSrc={replied_as?.logo}
                  publicToPrivateCommentId={private_replied_public_comment_id}
                  messageTranslated={message_translated}
                  replyPhrase={owned ? reply_phrase : undefined}
                  customTagsByUser={custom_tags_by_user}
                  customTagsByAutomation={custom_tags_by_automation}
                  postType={post_type}
                  username={username}
                  author={isLastMessageOfType(item, nextAfterClientMessage) ? username : undefined}
                />
                {!!brand_reply && (
                  <PrivateMessageCard
                    id={brand_reply.id}
                    parentId={parent.id}
                    isOwned={brand_reply.owned}
                    key={`message-${brand_reply.id}`}
                    message={brand_reply.message || ""}
                    isHidden={brand_reply.is_hidden}
                    attachments={brand_reply.attachments}
                    createdTimePretty={brand_reply.created_time_pretty}
                    replyPhrase={brand_reply.reply_phrase}
                    avatarSrc={brand_reply.replied_as?.logo}
                    publicToPrivateCommentId={brand_reply.private_replied_public_comment_id}
                    messageTranslated={brand_reply.message_translated}
                    postType={post_type}
                    username={username}
                    author={
                      isLastMessageOfType(brand_reply, nextAfterBrandReply)
                        ? brand_reply.username
                        : undefined
                    }
                  />
                )}
              </>
            );
          })}
        {postReplyRegionIsNotAllowedFailure && (
          <div className={s.bbSendImageError}>
            <WarningIcon />
            <span>{t("components:reply:replyPrivately:tiktok:regionIsNotAllowed")}</span>
          </div>
        )}
        <div id="end-of-the-chat" className={s.bbMessageListEndOfChat} ref={bottomRef} />
      </div>
      <div>
        {replyData?.reply_capability?.disabled ? (
          <AlertWarning
            size="large"
            message={replyData.reply_capability.tooltip}
            className={s.bbReplyCapabilityDisabledDisclaimer}
          />
        ) : (
          <>
            {replyApprovalData ? (
              <ApprovalMessageCard
                message={replyApprovalData.message || replyApprovalData.raw_message}
                onClickAction={onApprovalAction}
                approvalPhrase={replyApprovalData.approval_phrase}
                status={replyApprovalData.status}
                actions={replyApprovalData.actions}
                author={replyApprovalData.replier}
                loading={updatingReplyApproval}
              />
            ) : null}

            <Reply
              isPrivateReply
              comment={comment}
              onClickReply={onInternalReply}
              replyUser={replyData.reply_user}
              replierInfo={replyData.replier_info}
              isReplyBtnLoading={isReplyBtnLoading}
              maxMessageLength={replyData.max_message_length}
              replyingCommentId={replyData.replying_comment_id}
              suggestedReplyMessage={replyData?.suggested_reply_message}
              canSendDMMedia={replyData.parent.can_send_dm_media}
              canSendDMMediaTooltip={replyData.parent.can_send_dm_media_tooltip}
              disabledMentions={parent.brand_reply?.warning_type === "ig_mention_removed"}
              replyCapability={replyData.reply_capability}
            />
            {isTTComment && (
              <span className={s.bbMessageBottomDisclaimer}>
                {t("components:reply:replyPrivately:tiktok:disclaimerBottom")}
              </span>
            )}
          </>
        )}
        {showPublicToPrivateDisclaimer && (
          <span className={s.bbMessageBottomDisclaimer}>
            {t("components:reply:replyPrivately:disclaimerBottom")}
          </span>
        )}
        {parent.is_hidden && !isFacebookPlatformStrict(replyData.reply_user.platform.id) ? (
          <span className={s.bbMessageListHidden}>{t("components:reply:hiddenComments")}</span>
        ) : null}
      </div>
    </div>
  );
};
