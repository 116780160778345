import React from "react";
import { t } from "../../../../App";

import { PerformanceCardPill } from "@components/insights/_common/performanceCard/Pill";

import s from "./ProfilesTable.module.less";

export const ColumnWithGrowth = ({
  value,
  growth,
  showPercentage,
}: {
  value?: number | null;
  growth?: number | string | null;
  showPercentage?: boolean;
}) => {
  if (value == null && !growth) {
    return (
      <span className={s.bbColumnNotAvailable}>
        {t("components:listen:profiles:not_available")}
      </span>
    );
  }

  return (
    <>
      {`${value?.toLocaleString("en-US")}${showPercentage ? "%" : ""}`}
      {!!growth && (
        <PerformanceCardPill percentageValue={Number(growth)} className={s.bbColumnPercentPill} />
      )}
    </>
  );
};
