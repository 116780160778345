import classNames from "classnames";
import { useTranslation } from "react-i18next";
import React, { useState, useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";

import { Col, Grid, Row } from "antd";
import { Toggle, ButtonPermissionGuard, ChipIcon, EyeIcon } from "@bbdevcrew/bb_ui_kit_fe";
import WorkflowActions from "./item/WorkflowActions";

import { postShortenedSearchesAction } from "@store/shortenedSearches/actions";

import s from "./Workflows.module.less";

import { WORKFLOW_CHIP_MAP } from "./helpers";
import usePermissions from "@utils/usePermissions";
import { IWorkflowItemProps, SUPPORTED_WORKFLOW_CATEGORIES } from "./item/helpers";
import { WorkflowItemCategoryType } from "@store/workflows/types";

const WorkflowsTableRow: React.FC<
  IWorkflowItemProps & {
    index: number;
  }
> = ({ item: data, index, onEditClick, onDeleteClick, onWorkflowToggle }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const permissions = usePermissions();
  const screens = Grid.useBreakpoint();
  const isMobile = !screens.md;

  const [isActionsDropdownOpen, setIsActionsDropdownOpen] = useState(false);

  const canViewOrEdit = useMemo(
    () => SUPPORTED_WORKFLOW_CATEGORIES.includes(data.category) && data.is_editable,
    [data.category, data.is_editable],
  );

  const goToExplore = useCallback(
    () => dispatch(postShortenedSearchesAction({ filters: data?.trigger?.filters || {} })),
    // eslint-disable-next-line
    [dispatch],
  );

  const closeActionsDropdown = () => setIsActionsDropdownOpen(false);

  const workflowLabel = useCallback(
    (category: WorkflowItemCategoryType) => {
      if (category === "hide" && !data.enabled)
        return t("pages:workflows:workflowLabels:hideDisabled");
      return t(`pages:workflows:workflowLabels:${category}`);
    },
    [data.enabled, t],
  );

  const workflowIcon = useCallback(
    (category: WorkflowItemCategoryType) => {
      if (category === "hide" && !data.enabled) return <EyeIcon />;
      return WORKFLOW_CHIP_MAP[category].icon;
    },
    [data.enabled],
  );

  return (
    <Row className={classNames(s.bbTableRow, index % 2 === 0 && s.bbTableRowEven)} key={index}>
      <Col span={isMobile ? 4 : 2} className={s.bbTableRowCell}>
        {data.show_enable_checkbox && (
          <div className={s.bbWorkflowItemToggle} onClick={event => event.stopPropagation()}>
            <ButtonPermissionGuard
              hasPermission={!!permissions["manageWorkflows"]}
              message={t("permissions:editWorkflows")}
            >
              <Toggle
                id={`enabled-${data.id}`}
                checked={data.enabled}
                onChange={onWorkflowToggle}
                data-cy="workflow-toggle"
              />
            </ButtonPermissionGuard>
          </div>
        )}
      </Col>
      <Col span={isMobile ? 8 : 4} className={s.bbTableRowCell}>
        <ChipIcon
          _size="xs"
          _type={data.enabled ? WORKFLOW_CHIP_MAP[data.category].type : "grey"}
          text={workflowLabel(data.category)}
          icon={workflowIcon(data.category)}
          iconPosition="left"
        />
        {data.additional_actions?.map(action => (
          <React.Fragment key={action.category}>
            {action.category && (
              <>
                {" + "}
                <ChipIcon
                  _size="xs"
                  _type={
                    data.enabled && action.category
                      ? WORKFLOW_CHIP_MAP[action.category].type
                      : "grey"
                  }
                  text={workflowLabel(action.category)}
                  icon={workflowIcon(action.category)}
                  iconPosition="left"
                />
              </>
            )}
          </React.Fragment>
        ))}
      </Col>
      <Col span={isMobile ? 12 : 18} className={s.bbTableRowCell}>
        <div className={s.bbTableRowTitle}>
          <div>
            <h4>{data.title}</h4>
            <p>{data.description}</p>
          </div>
          {canViewOrEdit && (
            <WorkflowActions
              onEditClick={() => {
                onEditClick(closeActionsDropdown);
              }}
              onDeleteClick={() => {
                onDeleteClick(closeActionsDropdown);
              }}
              onExploreClick={goToExplore}
              isActionsDropdownOpen={isActionsDropdownOpen}
              setIsActionsDropdownOpen={setIsActionsDropdownOpen}
              showExploreAction={
                data.category === "email_alert" ||
                (data.category === "hide" &&
                  !!data.additional_actions?.some(action => action.category === "email_alert"))
              }
              className={s.bbTableRowActions}
            />
          )}
        </div>
      </Col>
    </Row>
  );
};

export default WorkflowsTableRow;
