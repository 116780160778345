import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import React, { useCallback, useMemo } from "react";

import { Form } from "antd";

import ClientPlanBadge from "../ClientPlanBadge/ClientPlanBadge";
import { AppSidebar } from "@bbdevcrew/bb_ui_kit_fe";

import {
  postSavedFilterIdSelector,
  postSavedFilterPendingSelector,
  postSavedFilterSuccessfulSelector,
  predefinedFiltersSelector,
} from "@store/savedFilters/selectors";
import { filterDataSelector, savedFilterValueSelector } from "@store/filters/selectors";
import { autocompleteOptionsSelector } from "@store/autocomplete/selectors";
import { clientDataSelector, meSelector } from "@store/me/selectors";
import { getAllModulesSectionsSelector } from "@store/modules/selectors";

import {
  deleteSavedFilterAction,
  postSavedFilterAction,
  putSavedFilterAction,
  resetSavedFiltersDataAction,
} from "@store/savedFilters/actions";
import { getModuleSectionsListAction } from "@store/modules/actions";
import { getAutocompleteOptionsAction } from "@store/autocomplete/actions";

import {
  debounce,
  IFormFilterRequest,
  IPostSavedFilterBodyProps,
  IGetAutocompleteOptionsPayload,
} from "@bbdevcrew/bb_ui_kit_fe";
import { getCurrentModuleName } from "@utils/paths";
import { getFetchValuesFieldName } from "@utils/filters";
import { useSavedFiltersList } from "@utils/useSavedFiltersList";
import { useFilterContext } from "./FilterContext";
import { IAvailableSectionsType } from "@store/modules/types";
import { IPutSavedFilterBodyProps } from "@store/savedFilters/types";
import { useGetDefaultFiltersConfig } from "../AppFilter/config";
import { unreadFeatures, ModuleIconMap, ShortcutsUrlMap } from "@utils/appSidebar";
import WorkflowsMenu from "@components/control-panel/workflows/menu/WorkflowsMenu";

const AppSideBarContainer: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [filtersForm] = Form.useForm<IFormFilterRequest>();

  const currentModuleId = getCurrentModuleName();
  const savedFiltersList = useSavedFiltersList();
  const { onSavedFilterChange } = useFilterContext();
  const defaultFiltersConfig = useGetDefaultFiltersConfig(t, false, filtersForm);

  const hasSidebar = ["insights", "control_panel"].includes(getCurrentModuleName());

  const me = useSelector(meSelector);
  const clientData = useSelector(clientDataSelector);
  const appFilters = useSelector(filterDataSelector);
  const predefinedFilters = useSelector(predefinedFiltersSelector);
  const autocompleteOptions = useSelector(autocompleteOptionsSelector);
  const isPostSavedPending = useSelector(postSavedFilterPendingSelector);
  const isPostSuccessful = useSelector(postSavedFilterSuccessfulSelector);
  const postSavedFilterId = useSelector(postSavedFilterIdSelector);
  const savedFilterValue = useSelector(savedFilterValueSelector);
  const modulesSections = useSelector(getAllModulesSectionsSelector);

  const allSections = modulesSections[currentModuleId as IAvailableSectionsType];
  const sections = allSections?.map(section => ({
    ...section,
    suffix:
      section.id === "control_panel" &&
      searchParams.get("section") === "control_panel-workflows" ? (
        <WorkflowsMenu />
      ) : undefined,
  }));

  const getModuleSectionsList = useCallback(
    (module_id: string) => {
      dispatch(getModuleSectionsListAction(module_id));
    },
    [dispatch],
  );

  // eslint-disable-next-line
  const getAutocompleteOptions = useCallback(
    debounce((payload: IGetAutocompleteOptionsPayload) => {
      dispatch(getAutocompleteOptionsAction(payload));
    }, 300),
    [dispatch],
  );

  const hasSavedFilters = useMemo(
    () =>
      hasSidebar &&
      searchParams.get("section") !== "report-compare" &&
      searchParams.get("section") !== "report-alerts" &&
      searchParams.get("section") !== "report-profiles" &&
      searchParams.get("section") !== "listen_more-listening_sources" &&
      searchParams.get("section") !== "listen_more-trending_hashtags" &&
      currentModuleId !== "control_panel",
    [hasSidebar, searchParams, currentModuleId],
  );

  return (
    <AppSidebar
      hasSavedFilters={hasSavedFilters}
      currentModuleId={currentModuleId}
      savedFiltersList={savedFiltersList}
      onSavedFilterChange={onSavedFilterChange}
      savedFilterValue={savedFilterValue}
      sections={sections}
      getModuleSectionsList={getModuleSectionsList}
      filtersConfig={defaultFiltersConfig}
      autocompleteOptions={autocompleteOptions}
      predefinedFilters={predefinedFilters}
      sanitizeFieldName={getFetchValuesFieldName}
      getAutocompleteOptions={getAutocompleteOptions}
      isPostSavedPending={isPostSavedPending}
      isPostSuccessful={isPostSuccessful}
      postSavedFilterId={postSavedFilterId}
      appFilters={appFilters}
      ModuleIconMap={ModuleIconMap}
      shortcutsUrlMap={ShortcutsUrlMap}
      unreadFeatures={unreadFeatures}
      availablePlatformTypes={clientData?.platform_types || []}
      onResetFilters={() => dispatch(resetSavedFiltersDataAction())}
      onDeleteSavedFilter={(id: string) => dispatch(deleteSavedFilterAction(id))}
      onCreateSavedFilter={(payload: IPostSavedFilterBodyProps) =>
        dispatch(postSavedFilterAction(payload))
      }
      onUpdateSavedFilter={(payload: IPutSavedFilterBodyProps) =>
        dispatch(putSavedFilterAction(payload))
      }
      showBottomSuffix={!!me?.client?.client_plan}
      bottomSuffix={(isSidebarCollapsed: boolean) =>
        !!me?.client?.client_plan ? (
          <ClientPlanBadge type={me?.client?.client_plan} showTitle={!isSidebarCollapsed} />
        ) : null
      }
    />
  );
};

export default AppSideBarContainer;
