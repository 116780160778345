import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { performCommentAction } from "@store/comments/actions";
import { addLinkedInReaction, deleteLinkedInReaction } from "@store/linkedInReactions/actions";

import {
  IComment,
  PostPlacementEnum,
  isTiktokPlatform,
  isLinkedinPlatform,
  isInstagramPlatform,
  isTrustpilotPlatform,
  isGoogleBusinessPlatform,
  isFacebookPlatformStrict,
} from "@bbdevcrew/bb_ui_kit_fe";
import { IFilters } from "@bbdevcrew/bb_ui_kit_fe";
import { CommentListType } from "@store/comments/types";
// eslint-disable-next-line max-len
import { LinkedInReactionClickFunctionType } from "@components/_common/CommentCard/commentActions/CommentActions.type";

function useCommentActions(filters: IFilters, listId?: CommentListType) {
  const dispatch = useDispatch();

  const archiveComment = useCallback(
    (id: string, value: boolean) => {
      dispatch(
        performCommentAction({
          id,
          value,
          action: "ignore",
          filters,
        }),
      );
    },
    [dispatch, filters],
  );

  const starComment = useCallback(
    (id: string, value: boolean) => {
      dispatch(
        performCommentAction({
          id,
          value,
          action: "star",
          filters,
        }),
      );
    },
    [dispatch, filters],
  );

  const flagComment = useCallback(
    (id: string, value: boolean) => {
      dispatch(
        performCommentAction({
          id,
          value,
          action: "flag",
          filters,
        }),
      );
    },
    [dispatch, filters],
  );

  const reviewComment = useCallback(
    (id: string, value: boolean) => {
      dispatch(
        performCommentAction({
          id,
          value,
          action: "review",
          filters,
        }),
      );
    },
    [dispatch, filters],
  );

  const hideComment = useCallback(
    (id: string, value: boolean) => {
      dispatch(
        performCommentAction({
          id,
          value,
          action: "hide",
          filters,
        }),
      );
    },
    [dispatch, filters],
  );

  const deleteComment = useCallback(
    (comment: IComment) => {
      dispatch(
        performCommentAction({
          id: comment.id,
          value: true,
          action: "delete",
          filters,
        }),
      );
    },
    [dispatch, filters],
  );

  const likeComment = useCallback(
    (id: string, value: boolean) => {
      dispatch(
        performCommentAction({
          id,
          value,
          action: "like",
          filters,
        }),
      );
    },
    [dispatch, filters],
  );

  const cancelComment = useCallback(
    (id: string, value: boolean) => {
      dispatch(
        performCommentAction({
          id,
          action: value ? "reply_cancel" : "reply_uncancel",
          filters,
          value,
        }),
      );
    },
    [dispatch, filters],
  );

  const likeLinkedInComment = useCallback<LinkedInReactionClickFunctionType>(
    (commentId, reaction) => {
      if (reaction) {
        dispatch(addLinkedInReaction(commentId, reaction));
      } else {
        dispatch(deleteLinkedInReaction(commentId));
      }
    },
    [dispatch],
  );

  const dismissCommentError = useCallback(
    (id: string) => {
      dispatch(
        performCommentAction({
          id,
          value: true,
          action: "dismiss_errors",
          filters,
          listId,
        }),
      );
    },
    [dispatch, filters, listId],
  );

  const isHideActionAvailable = useCallback((comment: IComment) => {
    const platformId = comment.platform_type.id;
    const postType = comment.post_type;
    const isLinkedInComment = isLinkedinPlatform(platformId);
    const isTrustpilotComment = isTrustpilotPlatform(platformId);
    const isGoogleBusinessComment = isGoogleBusinessPlatform(platformId);
    const isIgHashtagMention = isInstagramPlatform(platformId) && postType === "HASHTAG_MENTION";
    const isIgMediaTagMention = isInstagramPlatform(platformId) && postType === "MEDIA_TAG_MENTION";
    const isTiktokMention =
      isTiktokPlatform(platformId) &&
      (postType === "MENTION" || postType === "CAPTION_MENTION" || postType === "HASHTAG_MENTION");
    const isCommentDm = comment?.post_placement === PostPlacementEnum.DM;

    return (
      !isCommentDm &&
      !isLinkedInComment &&
      !isGoogleBusinessComment &&
      !isTrustpilotComment &&
      !isIgHashtagMention &&
      !isIgMediaTagMention &&
      !isTiktokMention
    );
  }, []);

  const isReplyActionAvailable = useCallback((comment: IComment) => {
    return !(
      isInstagramPlatform(comment.platform_type.id) &&
      (comment.post_type === "HASHTAG_MENTION" || comment.post_type === "MEDIA_TAG_MENTION")
    );
  }, []);

  const isDetailsActionAvailable = useCallback((comment: IComment) => {
    return (
      isInstagramPlatform(comment.platform_type.id) &&
      (comment.post_type === "HASHTAG_MENTION" || comment.post_type === "MEDIA_TAG_MENTION")
    );
  }, []);

  const isLikeActionAvailable = useCallback((comment: IComment) => {
    const { id: platformId } = comment.platform_type;
    return (
      comment.actions?.like_unlike_capability === "enabled" &&
      (isFacebookPlatformStrict(platformId) || isTiktokPlatform(platformId)) &&
      comment.post_placement !== PostPlacementEnum.DM
    );
  }, []);

  const isLinkedInReactionActionAvailable = useCallback((comment: IComment) => {
    const { id: platformId } = comment.platform_type;
    return isLinkedinPlatform(platformId) && comment.actions?.can_react;
  }, []);

  const isDeleteActionAvailable = useCallback((comment: IComment) => {
    return isLinkedinPlatform(comment.platform_type.id);
  }, []);

  const isCancelActionAvailable = useCallback((comment: IComment) => {
    return comment.actions?.reply_cancel_capability.capability === "enabled";
  }, []);

  return {
    archiveComment,
    starComment,
    flagComment,
    reviewComment,
    hideComment,
    deleteComment,
    likeComment,
    cancelComment,
    likeLinkedInComment,
    dismissCommentError,
    isHideActionAvailable,
    isLikeActionAvailable,
    isReplyActionAvailable,
    isDeleteActionAvailable,
    isDetailsActionAvailable,
    isLinkedInReactionActionAvailable,
    isCancelActionAvailable,
  };
}

export default useCommentActions;
