import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { PostBased } from "@components/_common/PostBased/PostBased";
import { CareReviewTag } from "@components/_common/CareReviewTag/CareReviewTag";

import { meSelector } from "@store/me/selectors";

import { IFilters } from "@bbdevcrew/bb_ui_kit_fe";
import { useGetDefaultFiltersConfig } from "@components/_common/AppFilter/config";

export const useWorkflowTriggerFilters = (
  filterValues: IFilters,
  hideCareReviewTag?: boolean,
  hiddenFilters?: string[],
  hiddenPlatformTypes?: string[],
) => {
  const { t } = useTranslation();
  const me = useSelector(meSelector);

  const filters = useGetDefaultFiltersConfig(t, true, undefined, hiddenPlatformTypes);

  const [careReviewTag, setCareReviewTag] = useState(false);

  const keywordsIdx = filters.findIndex(obj => obj.name === "keyword_query");
  const postIdsIdx = filters.findIndex(obj => obj.name === "post_ids");
  const platformTypesIdx = filters.findIndex(obj => obj.name === "platform_types");
  const messageTypesIdx = filters.findIndex(obj => obj.name === "message_types");

  const messageTypesFilteredAllOptions = me?.message_types?.items?.filter(
    ({ id }) => id !== "owned_private" && id !== "earned",
  );

  if (keywordsIdx > -1) {
    filters[keywordsIdx].props = {
      ...filters[keywordsIdx].props,
      maxChipCount: 2,
    };
  }

  if (messageTypesIdx > -1) {
    filters[messageTypesIdx].props = {
      ...filters[messageTypesIdx].props,
      options: messageTypesFilteredAllOptions,
    };
  }

  if (postIdsIdx > -1 && !hiddenFilters?.includes("is_post_based")) {
    filters.splice(postIdsIdx + 1, 0, {
      name: "is_post_based",
      type: "asset",
      component: PostBased,
      label: "",
      props: {
        checked: !!filterValues?.is_post_based,
      },
    });
  }

  if (platformTypesIdx > -1) {
    filters[platformTypesIdx].props = {
      ...filters[platformTypesIdx].props,
      isDisabled: () => false,
    };
  }

  if (!hideCareReviewTag && !!me?.client_data.care_review_tag_options?.length) {
    filters.unshift({
      name: "care_review_tags",
      type: "comment",
      component: CareReviewTag,
      label: "",
      props: {
        onCareReviewTagChange: setCareReviewTag,
      },
    });
  }

  useEffect(() => {
    if (hideCareReviewTag) return;
    if (!!filterValues?.care_review_tags?.length) setCareReviewTag(true);
    // eslint-disable-next-line
  }, [!!filterValues?.care_review_tags?.length]);

  return {
    careReviewTagChecked: careReviewTag,
    workflowTriggerFilters: filters.filter(({ name }) => !hiddenFilters?.includes(name)),
  };
};

export function filterHasNonEmptyValue(obj: unknown): boolean {
  if (typeof obj !== "object" || obj === null) return false;

  if (Array.isArray(obj)) return obj.some(filterHasNonEmptyValue);

  for (const value of Object.values(obj)) {
    if (
      value !== undefined &&
      value !== "" &&
      !(Array.isArray(value) && value.length === 0) &&
      !(typeof value === "object" && value !== null && Object.keys(value).length === 0)
    ) {
      return true;
    }

    if (typeof value === "object" && value !== null && filterHasNonEmptyValue(value)) {
      return true;
    }
  }

  return false;
}
