import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Header from "@components/insights/_common/header";
import MobileFiltersButton from "@components/_common/AppFilter/mobileFiltersButton";
import { OverviewSummary } from "./OverviewSummary";
import { OverviewBBNews } from "./OverviewBBNews";
import { OverviewSocialMedia } from "./OverviewSocialMedia";
import { OverviewWorkflows } from "./OverviewWorkflows";

import { workflowsFetchedSelector, workflowsFetchingSelector } from "@store/workflows/selectors";
import { postDashboardAction } from "@store/dashboard/actions";
import { getWorkflowsAction } from "@store/workflows/actions";

import s from "./Overview.module.less";

import { DashboardComponentType } from "@store/modules/types";
import { IDashboardPayload } from "@store/dashboard/types";
import { useCurrentFilters } from "@utils/useCurrentFilters";

import { LightbulbIcon } from "@assets/index";

export const Overview: React.FC = () => {
  const dispatch = useDispatch();
  const filters = useCurrentFilters();
  const { t } = useTranslation();

  const workflowsLoading = useSelector(workflowsFetchingSelector);
  const workflowsLoaded = useSelector(workflowsFetchedSelector);

  const getDashboardData = useCallback(
    (data: IDashboardPayload) => dispatch(postDashboardAction(data)),
    [dispatch],
  );

  useEffect(() => {
    const components: DashboardComponentType[] = ["action_summary_stats"];
    if (filters) {
      getDashboardData({
        filters,
        components,
        options: {
          action_summmary_stats_extra_elements: true,
        },
      });
    }

    if (!workflowsLoading && !workflowsLoaded) dispatch(getWorkflowsAction());
  }, [filters, workflowsLoading, workflowsLoaded, getDashboardData, dispatch]);

  return (
    <div className={s.bbOverview}>
      <Header>
        <MobileFiltersButton />
      </Header>
      <div className={s.bbOverviewTopWidgets}>
        <OverviewSummary />
        <OverviewWorkflows />
      </div>
      <div className={s.bbOverviewNewsContainer}>
        <div className={s.bbOverviewNewsTitle}>
          <LightbulbIcon />
          <span>{t("components:controlPanel:overview:news:title")}</span>
        </div>
        <div className={s.bbOverviewBottomWidgets}>
          <OverviewBBNews />
          <OverviewSocialMedia />
        </div>
      </div>
    </div>
  );
};
