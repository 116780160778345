import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import CustomTagsDropdown from "@components/_common/CommentCard/customTags/CustomTagsDropdown";
import { Button, ChevronDownIcon } from "@bbdevcrew/bb_ui_kit_fe";

import { customTagsSelector, getCustomTagsSuccessfulSelector } from "@store/customTags/selectors";
import { getCustomTagsAction } from "@store/customTags/actions";

import s from "./TagWorkflowAction.module.less";

import { ICustomTag } from "@bbdevcrew/bb_ui_kit_fe";
import { IFormComponent } from "@components/control-panel/workflows/types";

export const CustomTagWorkflowAction: React.FC<IFormComponent<string[]>> = ({
  value,
  onChange,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [tag, setTag] = useState<ICustomTag>();
  const [initialized, setInitialized] = useState<false | string>(false);

  const customTags = useSelector(customTagsSelector);
  const fetchedCustomTags = useSelector(getCustomTagsSuccessfulSelector);

  useEffect(() => {
    dispatch(getCustomTagsAction());
  }, [dispatch]);

  useEffect(() => {
    if (fetchedCustomTags && !!value?.length) {
      setInitialized(value[0].split("::")[1]);
    }
  }, [fetchedCustomTags, value]);

  useEffect(() => {
    if (initialized && value) {
      const defaultTag = customTags.find(customTag => customTag.id === initialized);
      setTag(defaultTag);
    }
    // eslint-disable-next-line
  }, [initialized]);

  useEffect(() => {
    if (tag) {
      const updatedId = `custom_tag::${tag.id}`;
      if (!value || updatedId !== value[0]) onChange?.([updatedId]);
    }
    // eslint-disable-next-line
  }, [tag, onChange]);
  return (
    <CustomTagsDropdown
      onSelectTag={setTag}
      _dropdownPlacement="topLeft"
      wrapperClassName={s.bbWorkflowItemFormTagSelectWrapper}
      trigger={
        <Button className={s.bbWorkflowItemFormTagSelect} type="button">
          <span className={s.bbWorkflowItemFormTagSelectLabel}>
            {(tag && tag?.label) || t("pages:workflows:form:actions:tag:placeholder")}
          </span>
          <i className={s.bbWorkflowItemFormTagSelectChevron}>
            <ChevronDownIcon />
          </i>
        </Button>
      }
    />
  );
};
