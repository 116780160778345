import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { Form } from "antd";
import { Button, Filters, SelectStyled } from "@bbdevcrew/bb_ui_kit_fe";
import { TimeFrameTrigger } from "./TagWorkflowTriggerTimeFrame";

import { clientDataSelector, meSelector } from "@store/me/selectors";
import { autocompleteOptionsSelector } from "@store/autocomplete/selectors";
import { getAutocompleteOptionsAction } from "@store/autocomplete/actions";

import s from "./TagWorkflowTrigger.module.less";

import {
  IFilters,
  IFormHandler,
  IFormFilterRequest,
  IGetAutocompleteOptionsPayload,
} from "@bbdevcrew/bb_ui_kit_fe";
import { debounce } from "@bbdevcrew/bb_ui_kit_fe";
import { getFetchValuesFieldName } from "@utils/filters";
import { ITimeFrameTrigger } from "@store/workflows/types";
import { getDefaultTimeFrameValues, getOptions } from "./TagWorkflowTrigger.helpers";
import { IFiltersPanelProps, ITagWorkflowTriggerProps } from "./TagWorkflowTrigger.types";
import { useWorkflowTriggerFilters } from "../allFiltersWorkflowTrigger/AllFiltersWorkflowTrigger.helpers"; // eslint-disable-line max-len

import { ChevronDownIcon, ChevronUpIcon } from "@bbdevcrew/bb_ui_kit_fe";

const FiltersPanel: React.FC<IFiltersPanelProps> = ({ value, hiddenFilters, onInternalChange }) => {
  const { t } = useTranslation();
  const filtersRef = useRef<IFormHandler>(null);
  const dispatch = useDispatch();
  const [filtersForm] = Form.useForm<IFormFilterRequest>();
  const { workflowTriggerFilters } = useWorkflowTriggerFilters(value, true, hiddenFilters);

  const [fullHeight, setFullHeight] = React.useState(false);

  const clientData = useSelector(clientDataSelector);
  const autocompleteOptions = useSelector(autocompleteOptionsSelector);

  // eslint-disable-next-line
  const getAutocompleteOptions = useCallback(
    debounce((payload: IGetAutocompleteOptionsPayload) => {
      dispatch(getAutocompleteOptionsAction(payload));
    }, 300),
    [dispatch],
  );

  useEffect(() => {
    if (filtersRef?.current && value) {
      filtersRef.current.initializeFormValues(value);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Filters
        ref={filtersRef}
        form={filtersForm}
        onChangeCallback={onInternalChange}
        filtersConfig={workflowTriggerFilters}
        onFilter={() => {}} // eslint-disable-line
        clientPlatformTypes={clientData?.platform_types || []}
        className={classNames(s.bbAppFiltersFormWrapper, {
          [s.bbAppFiltersFormWrapperFullHeight]: fullHeight,
        })}
        autocompleteOptions={autocompleteOptions}
        getAutocompleteOptions={getAutocompleteOptions}
        sanitizeFieldName={getFetchValuesFieldName}
      />
      <Button
        className={s.bbViewMoreButton}
        _type="link"
        onClick={() => setFullHeight(!fullHeight)}
      >
        {t(`pages:workflows:form:triggers:email_alert:${fullHeight ? "viewLess" : "viewMore"}`)}
        {fullHeight ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </Button>
    </>
  );
};

export const TagWorkflowTrigger: React.FC<ITagWorkflowTriggerProps> = ({
  value,
  form,
  hiddenFilters,
  onChange,
}) => {
  const { t } = useTranslation();

  const [triggerType, setTriggerType] = useState(
    value?.time_frame ? "time_frame" : "filters_match",
  );

  const isTimeFrame = triggerType === "time_frame";
  const isFiltersMatch = triggerType === "filters_match";

  const filtersValue = form.getFieldValue("trigger")?.filters || {};

  const me = useSelector(meSelector);

  const timeFrameValues = value?.time_frame || getDefaultTimeFrameValues(me?.timezone);

  const onTriggerTypeChange = (type: string) => {
    setTriggerType(type);

    if (type === "time_frame") {
      onChange?.({
        keyword_query: undefined,
        time_frame: timeFrameValues,
      });
    } else {
      onChange?.({ keyword_query: [], time_frame: undefined });
    }
  };

  const onTimeFrameChange = (time_frame: ITimeFrameTrigger) =>
    onChange?.({ keyword_query: undefined, time_frame });

  const onFiltersChange = (filters: IFilters) =>
    form.setFieldValue(["trigger", "filters"], filters);

  return (
    <div className={s.bbTagTrigger}>
      <div className={s.bbTagTriggerType}>
        <span>{t("generic:when")}</span>
        <SelectStyled
          wrapperClassName={s.bbTagTriggerSelect}
          dropdownMenuClassName={s.bbTagTriggerSelectMenu}
          value={triggerType}
          onChange={onTriggerTypeChange}
          options={getOptions()}
        />
        {!isFiltersMatch && <span>{t("generic:is")}</span>}
      </div>
      {isTimeFrame && (
        <Form.Item
          name={["trigger", "time_frame"]}
          initialValue={timeFrameValues}
          className={s.bbTagTriggerFormItem}
        >
          <TimeFrameTrigger onChange={onTimeFrameChange} />
        </Form.Item>
      )}
      {isFiltersMatch && (
        <Form.Item name={["trigger", "filters"]} className={s.bbTagTriggerFormItem}>
          <FiltersPanel
            value={filtersValue}
            onInternalChange={onFiltersChange}
            hiddenFilters={hiddenFilters}
          />
        </Form.Item>
      )}
    </div>
  );
};
