import classNames from "classnames/bind";
import { useForm } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import React, { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Input,
  Button,
  Tooltip,
  Accordion,
  TextareaWhite,
  FormErrorMessage,
  DeleteConfirmation,
} from "@bbdevcrew/bb_ui_kit_fe";
import { Form } from "antd";
import UsernameSettings from "./UsernameSettings";
import BrandBastionTags from "../../../CommentCard/brandBastionTags/BBTags";
import CustomTagsList from "../../../CommentCard/customTags/CustomTagsList";
import CommentTagsList from "../../../CommentCard/commentTags/CommentTagsList";
import CustomTagsDropdown from "../../../CommentCard/customTags/CustomTagsDropdown";

import {
  getSavedReplyBBTagsSelector,
  getCreateSavedReplyCustomTagsSelector,
  fetchingSavedRepliesVariationsSelector,
  fetchedSavedRepliesVariationsSelector,
  savedRepliesVariationsSelector,
  getSavedRepliesSelector,
} from "@store/replies/selectors";
import {
  putSavedReplyAction,
  postSavedReplyAction,
  postReplyBBTagAction,
  deleteReplyBBTagAction,
  getSavedRepliesVariationsAction,
  setSavedReplyCustomTags,
  setSavedReplyBBTags,
  clearSavedRepliesVariationsAction,
} from "@store/replies/actions";
import { canManageBBTagsSelector } from "@store/me/selectors";
import { deleteCustomTagFromReplyAction } from "@store/customTags/actions";

import s from "./SavedReplyForm.module.less";

import { ICommentTag } from "@bbdevcrew/bb_ui_kit_fe";
import { IVariation, MAX_VARIATIONS_LENGTH, ISavedReplyFormProps } from "./SavedReplyForm.helpers";
import { SavedReplyPayloadType, SavedReplyType, UsernameOptionType } from "@store/replies/types";
import { SavedReplyMessageMaxLimit, SavedReplyNameMaxLimit, SavedReplyTabKeys } from "../helper";

import {
  DeleteIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  CommentIcon,
  MagicSparklesIcon,
  PlusIcon,
  SettingsIcon,
} from "@bbdevcrew/bb_ui_kit_fe";

const SavedReplyForm: React.FC<ISavedReplyFormProps> = ({ tab, editItemId, onViewChange }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = useForm();

  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const [usernameOption, setUsernameOption] = useState<UsernameOptionType>("skip");
  const [variations, setVariations] = useState<IVariation[]>([]);
  const [userSettingsCollapsed, setUserSettingsCollapsed] = useState(true);
  const [allCollapsedExpandedBtnText, setAllCollapsedExpandedBtnText] = useState("collapseAll");
  const [showVariationEmptyError, setShowVariationEmptyError] = useState(false);

  const maxVariationsReached = variations.length === MAX_VARIATIONS_LENGTH;

  const bbTags = useSelector(getSavedReplyBBTagsSelector);
  const canManageBBTag = useSelector(canManageBBTagsSelector);
  const customTags = useSelector(getCreateSavedReplyCustomTagsSelector);
  const generatedVariationsList = useSelector(savedRepliesVariationsSelector);
  const generatingVariations = useSelector(fetchingSavedRepliesVariationsSelector);
  const generatedVariations = useSelector(fetchedSavedRepliesVariationsSelector);
  const savedReplies = useSelector(getSavedRepliesSelector);

  const replies = useMemo(() => {
    return tab === SavedReplyTabKeys.CustomReplies
      ? savedReplies?.items
      : tab === SavedReplyTabKeys.BBReplies
        ? savedReplies?.internal_items
        : [];
  }, [savedReplies?.items, savedReplies?.internal_items, tab]);

  const generateSavedRepliesVariations = useCallback(
    (message: string) => dispatch(getSavedRepliesVariationsAction(message)),
    [dispatch],
  );

  const clearSavedRepliesVariations = useCallback(
    () => dispatch(clearSavedRepliesVariationsAction()),
    [dispatch],
  );

  useEffect(() => {
    if (generatedVariations && generatedVariationsList?.length) {
      const normalizedVariations: IVariation[] = generatedVariationsList.map((text: string) => ({
        text,
        collapsed: false,
        showDeleteConfirmation: false,
      }));
      const updatedVariations = [...variations, ...normalizedVariations].slice(
        0,
        MAX_VARIATIONS_LENGTH,
      );
      setVariations(updatedVariations);
      updatedVariations.forEach((variation, index) => {
        form.setFieldValue("variation-" + index, variation.text);
      });
      clearSavedRepliesVariations();
    }
    // eslint-disable-next-line
  }, [generatedVariations, generatedVariationsList]);

  useEffect(() => {
    if (editItemId) {
      const editItem = replies?.find(reply => reply.id === editItemId);

      if (editItem) {
        setUsernameOption(editItem?.username_option || "skip");
        setVariations(
          editItem?.variations.map(variation => ({
            text: variation.message,
            collapsed: false,
            showDeleteConfirmation: false,
          })) || [],
        );
        dispatch(setSavedReplyCustomTags(editItem.custom_tags || []));
        dispatch(setSavedReplyBBTags(editItem.tags || []));
        form.setFieldValue("name", editItem.name);
        form.setFieldValue("username_option", editItem?.username_option);
        form.setFieldValue("username_placeholder_word", editItem?.username_placeholder_word);
        editItem?.variations.forEach((variation, _index) => {
          form.setFieldValue("variation-" + _index, variation.message);
        });
      }
    } else {
      setVariations([{ text: "", collapsed: false, showDeleteConfirmation: false }]);
    }
    // eslint-disable-next-line
  }, [editItemId]);

  const onFinish = (values: SavedReplyPayloadType) => {
    const selectedTabName = tab === SavedReplyTabKeys.CustomReplies ? "custom" : "internal";

    const createPayload = {
      type: selectedTabName as SavedReplyType,
      name: values.name.trim(),
      username_option: usernameOption,
      username_placeholder_word:
        usernameOption === "use_word"
          ? values.username_placeholder_word?.trim() || undefined
          : undefined,
      variations: variations
        .filter(({ text }) => text.trim() !== "")
        .map(variation => variation.text),
      custom_tags: customTags.map(tag => tag.id).filter(id => id !== undefined) as string[],
      tags: bbTags.map(tag => tag.id),
    };

    if (editItemId) {
      dispatch(
        putSavedReplyAction({
          id: editItemId,
          ...createPayload,
        }),
      );
    } else {
      dispatch(postSavedReplyAction(createPayload));
    }
    form.resetFields();
    onViewChange("list", tab);
  };

  const onCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setVariations([{ text: "", collapsed: false, showDeleteConfirmation: false }]);
    onViewChange("list", tab);
  };

  const onDeleteCustomTag = (tagId?: string) => {
    tagId && dispatch(deleteCustomTagFromReplyAction(tagId));
  };

  const onDeleteBBTag = (tagId: string) => {
    dispatch(deleteReplyBBTagAction(tagId));
  };

  const onAddBBTag = ({ id, label, category }: ICommentTag) => {
    dispatch(postReplyBBTagAction({ id, label, category }));
  };

  const onUsernameOptionChange = (value: string) => {
    setUsernameOption(value as UsernameOptionType);
    form.setFieldValue("username_option", value);
  };

  const onVariationChange = (value: string, index: number) => {
    const newVariations = [...variations];
    newVariations[index].text = value;
    setVariations(newVariations);

    if (showVariationEmptyError && newVariations[0].text.trim() !== "") {
      setShowVariationEmptyError(false);
    }
  };

  const onValuesChange = () => {
    const fieldsErrors = form.getFieldsError();
    const mappedErrors = fieldsErrors.reduce(
      (accErrors, error) => ({
        ...accErrors,
        ...(!!error.errors[0] && { [error.name[0]]: error.errors[0] }),
      }),
      {},
    );

    setErrors(mappedErrors);
  };

  const onAddVariation = () => {
    const updatedVariations = [
      ...variations,
      { text: "", collapsed: false, showDeleteConfirmation: false },
    ];

    setVariations(updatedVariations);
    updatedVariations.forEach((variation, index) => {
      form.setFieldValue("variation-" + index, variation.text);
    });
  };

  const onDeleteVariationClick = (e: MouseEvent<HTMLSpanElement>, index: number) => {
    e.stopPropagation();
    setVariations(
      variations.map((variation, _index) =>
        _index === index ? { ...variation, showDeleteConfirmation: true } : variation,
      ),
    );
  };

  const onDeleteVariationConfirm = (index: number) => {
    const updatedVariations = variations.filter((_, i) => i !== index);
    setVariations(updatedVariations);
    updatedVariations.forEach((variation, _index) => {
      form.setFieldValue("variation-" + _index, variation.text);
    });
  };

  const onDeleteVariationCancel = (index: number) => {
    setVariations(
      variations.map((variation, _index) =>
        _index === index ? { ...variation, showDeleteConfirmation: false } : variation,
      ),
    );
  };

  const onCollapseVariation = (index: number) => {
    setVariations(
      variations.map((variation, i) =>
        i === index ? { ...variation, collapsed: !variation.collapsed } : variation,
      ),
    );
  };

  const onCollapseExpandAllVariations = () => {
    if (allCollapsedExpandedBtnText === "collapseAll") {
      setVariations(variations.map(variation => ({ ...variation, collapsed: true })));
      setAllCollapsedExpandedBtnText("expandAll");
      setUserSettingsCollapsed(true);
    } else {
      setVariations(variations.map(variation => ({ ...variation, collapsed: false })));
      setAllCollapsedExpandedBtnText("collapseAll");
      setUserSettingsCollapsed(false);
    }
  };

  const onGenerateVariationsClick = () => {
    if (maxVariationsReached) return;

    if (variations.every(variation => !variation.text.trim()) || variations[0].text.trim() === "") {
      setShowVariationEmptyError(true);
      return;
    }

    generateSavedRepliesVariations(variations[0].text);
  };

  const variationRequiredRules = [
    {
      required: true,
      message: (
        <FormErrorMessage>
          {t("components:reply:bbSavedReplies:create:variationError")}
        </FormErrorMessage>
      ),
    },
    {
      // If contains only whitespaces
      pattern: /^(?!\s*$).+/,
      validateTrigger: "onSubmit",
      message: (
        <FormErrorMessage>
          {t("components:reply:bbSavedReplies:create:variationError")}
        </FormErrorMessage>
      ),
    },
  ];

  return (
    <>
      <div className={s.bbSavedReplyHeader}>
        <Button _type="link" className={s.bbSavedReplyHeaderBack} onClick={onCancel}>
          <ChevronLeftIcon />
        </Button>
        <div className={s.bbSavedReplyHeaderTitle}>
          {t("components:reply:bbSavedReplies:create:title")}
        </div>
      </div>
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
        onFieldsChange={onValuesChange}
        className={s.bbSavedReplyCreateForm}
      >
        <Form.Item
          name="name"
          label={t("components:reply:bbSavedReplies:create:nameInputLabel")}
          rules={[
            {
              required: true,
              message: (
                <FormErrorMessage>
                  {t("components:reply:bbSavedReplies:create:nameInputError")}
                </FormErrorMessage>
              ),
            },
            {
              max: SavedReplyNameMaxLimit,
              message: t("components:reply:savedReplies:create:messageCharLimit", {
                amount: SavedReplyNameMaxLimit,
              }),
            },
            {
              // If contains only whitespaces
              pattern: /^(?!\s*$).+/,
              validateTrigger: "onSubmit",
              message: t("components:reply:savedReplies:create:nameInputError"),
            },
          ]}
        >
          <Input
            _size="md"
            wrapperClassName={classNames(s.bbSavedReplyCreateFormInput, {
              [s.bbSavedReplyCreateFormInputError]: errors.hasOwnProperty("name"),
            })}
            placeholder={t("components:reply:bbSavedReplies:create:namePlaceholder")}
          />
        </Form.Item>
        <div className={s.bbSavedRepliesTagsTitle}>
          {t("components:reply:bbSavedReplies:create:tagsTitle")}
        </div>
        <div className={s.bbSavedRepliesTags}>
          <CommentTagsList tags={bbTags} isReply className={s.bbCommentTagsList} />
          <CustomTagsList customTagsByUser={customTags} onDeleteTag={onDeleteCustomTag} />
          <CustomTagsDropdown
            customTagsByUser={customTags}
            customTagsAutomated={[]}
            hasTags={!!customTags.length}
          />
          {canManageBBTag && (
            <BrandBastionTags tags={bbTags} onAddTag={onAddBBTag} onDeleteTag={onDeleteBBTag} />
          )}
        </div>
        <div className={s.bbSavedReplyCreateFormMessages}>
          <div className={s.bbSavedReplyCreateFormMessagesTitle}>
            {t("components:reply:bbSavedReplies:create:replyMessagesTitle")}
            <span>
              {variations.length}/{MAX_VARIATIONS_LENGTH}
            </span>
          </div>
          <div className={s.bbCollapseAll}>
            <Button _size="sm" _type="link" onClick={onCollapseExpandAllVariations}>
              {allCollapsedExpandedBtnText === "collapseAll" ? (
                <ChevronUpIcon />
              ) : (
                <ChevronDownIcon />
              )}
              {t(`components:reply:bbSavedReplies:create:${allCollapsedExpandedBtnText}`)}
            </Button>
          </div>
        </div>

        <Accordion
          collapsed={userSettingsCollapsed}
          setCollapsed={() => setUserSettingsCollapsed(!userSettingsCollapsed)}
          headerPrefix={
            <span className={s.bbSavedReplyBlockIcon}>
              <SettingsIcon />
            </span>
          }
          title={t("components:reply:bbSavedReplies:create:usernameSettings:title")}
          body={
            <UsernameSettings
              usernameOption={usernameOption}
              onUsernameOptionChange={onUsernameOptionChange}
            />
          }
        />

        {variations.map((variation, index) => (
          <Accordion
            key={index}
            collapsed={variation.collapsed}
            setCollapsed={() => onCollapseVariation(index)}
            headerPrefix={
              <span className={s.bbSavedReplyBlockIcon}>
                <CommentIcon />
              </span>
            }
            headerSuffix={
              index === 0 ? undefined : (
                <span onClick={e => onDeleteVariationClick(e, index)}>
                  <DeleteIcon />
                </span>
              )
            }
            bottomSuffix={
              <>
                {variation.showDeleteConfirmation && (
                  <DeleteConfirmation
                    message={t("components:reply:bbSavedReplies:create:deleteVariationQuestion")}
                    onConfirm={() => onDeleteVariationConfirm(index)}
                    onCancel={() => onDeleteVariationCancel(index)}
                    className={s.bbDeleteConfirmation}
                  />
                )}
              </>
            }
            title={t("components:reply:bbSavedReplies:create:variationTitle") + (index + 1)}
            body={
              <Form.Item
                name={"variation-" + index}
                rules={[
                  {
                    max: SavedReplyMessageMaxLimit,
                    message: (
                      <FormErrorMessage>
                        {t("components:reply:bbSavedReplies:create:variationCharLimit", {
                          amount: SavedReplyMessageMaxLimit,
                        })}
                      </FormErrorMessage>
                    ),
                  },
                  ...(index === 0 ? variationRequiredRules : []),
                ]}
              >
                <TextareaWhite
                  _size="lg"
                  value={variation.text}
                  hasError={errors.hasOwnProperty("variation-" + index)}
                  onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                    onVariationChange(e.target.value, index)
                  }
                  className={classNames(s.bbSavedReplyCreateFormVariation, {
                    [s.bbSavedReplyCreateFormVariationError]: errors.hasOwnProperty(
                      "variation-" + index,
                    ),
                  })}
                />
              </Form.Item>
            }
          />
        ))}

        <div className={s.bbSavedReplyCreateFormGenerateActions}>
          <Tooltip
            title={
              maxVariationsReached
                ? t("components:reply:bbSavedReplies:create:maxVariationsReached", {
                    amount: MAX_VARIATIONS_LENGTH,
                  })
                : ""
            }
          >
            <span>
              <Button
                _size="sm"
                _type="primary"
                disabled={maxVariationsReached}
                className={s.bbGenerateBtn}
                loading={generatingVariations}
                onClick={onGenerateVariationsClick}
              >
                <MagicSparklesIcon />
                {t("components:reply:bbSavedReplies:create:generateVariations")}
              </Button>
            </span>
          </Tooltip>
          {showVariationEmptyError && (
            <div className={s.bbGenerateVariationsError}>
              <FormErrorMessage>
                {t("components:reply:bbSavedReplies:create:generateVariationsError")}
              </FormErrorMessage>
            </div>
          )}
          <Tooltip
            title={
              maxVariationsReached
                ? t("components:reply:bbSavedReplies:create:maxVariationsReached", {
                    amount: MAX_VARIATIONS_LENGTH,
                  })
                : ""
            }
          >
            <span>
              <Button
                _size="sm"
                _type="secondary"
                onClick={onAddVariation}
                disabled={maxVariationsReached}
              >
                <PlusIcon />
                {t("components:reply:bbSavedReplies:create:addVariation")}
              </Button>
            </span>
          </Tooltip>
        </div>

        <Form.Item>
          <div className={s.bbSavedReplyCreateFormActions}>
            <Button _type="secondary" onClick={onCancel}>
              {t("generic:discard")}
            </Button>
            <Button _type="primary" type="submit">
              {t("generic:save")}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};

export default SavedReplyForm;
