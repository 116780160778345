import {
  ICustomTag,
  IAiTag,
  ICommentTag,
  IReplyPhrase,
  IPrivateAttachment,
} from "@bbdevcrew/bb_ui_kit_fe";
import { PostTypes } from "@store/replies/types";

export interface IPrivateMessageCardProps {
  id: string;
  parentId: string;
  author?: string;
  avatarSrc?: string;
  isHidden?: boolean;
  isOwned?: boolean;
  message: string;
  replyPhrase?: IReplyPhrase;
  createdTime?: number;
  createdTimePretty?: string;
  messageTranslated?: string;
  attachments?: IPrivateAttachment[];
  customTagsByUser?: ICustomTag[];
  customTagsByAutomation?: ICustomTag[];
  aiTags?: IAiTag[];
  tags?: ICommentTag[];
  postType?: PostTypes;
  username: string;
  publicToPrivateCommentId?: string;
}

export const AVATAR_SIZE = 24;
export const DATE_FORMAT = "DD MMM, YYYY";
export const TIME_FORMAT = "hh:mm:ss A";
