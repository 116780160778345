import React from "react";

import { Col, Row } from "antd";
import { Card } from "@bbdevcrew/bb_ui_kit_fe";
import { ManagedSummaryCard } from "./SummaryCard";

import s from "./Summary.module.less";

import { IManagedSummaryProps } from "../Transparency.types";
import { IActionSummaryStat } from "@store/dashboard/types";

const CARDS_COUNT = 4;

export const ManagedSummary = ({
  data = [],
  isLoading,
  skeletonCount = CARDS_COUNT,
  cardTooltips,
  action,
}: IManagedSummaryProps) => {
  const cards = isLoading ? Array(skeletonCount).fill(null) : data;

  return (
    <Row gutter={[20, 28]} className={s.bbManagedSummaryWrapper}>
      {cards?.map((item, index) => (
        <Col xs={24} sm={12} lg={24 / cards.length} key={index} className={s.bbManagedSummary}>
          <Card className={s.bbManagedSummaryCard}>
            <div className={s.bbManagedSummaryCardContainer}>
              <ManagedSummaryCard
                {...item}
                isLoading={isLoading}
                cardTooltip={
                  item?.id ? cardTooltips?.[item.id as IActionSummaryStat["id"]] : undefined
                }
                action={item?.id ? action?.(item) : undefined}
              />
            </div>
          </Card>
        </Col>
      ))}
    </Row>
  );
};
