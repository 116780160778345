import React, { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  FilterLabel,
  InlineAlert,
  FormErrorMessage,
  SimpleSelectSingle,
} from "@bbdevcrew/bb_ui_kit_fe";
import { Form } from "antd";
import ExpansionPanel, { ExpansionPanelGroup } from "../../ExpansionPanel";

import { clientDataSelector } from "@store/me/selectors";

import s from "./ReplyTrigger.module.less";

import { normalizeFilterPlatforms } from "@bbdevcrew/bb_ui_kit_fe";
import { optionRequiredValidator } from "@utils/optionRequiredFormValidator";
import { useGetDefaultFiltersConfig } from "@components/_common/AppFilter/config";
import { fieldGroups, messageTypeOptions, triggerFields } from "./ReplyTrigger.helpers";

import { IReplyTriggerComponentProps, IReplyTriggerProps } from "./ReplyTrigger.types";

const ReplyTriggerComponent: React.FC<IReplyTriggerComponentProps> = ({
  name,
  label,
  component: Component,
  props = {},
  ...formProps
}) => {
  const hasError = Form.useFormInstance().getFieldError(name).length > 0;
  const tooltip = formProps.tooltip ? `${formProps.tooltip}` : undefined;

  return (
    <>
      <FilterLabel label={label} tooltip={tooltip} />
      <Form.Item name={[name]} {...formProps}>
        <Component {...props} hasError={hasError} />
      </Form.Item>
    </>
  );
};

export const ReplyTrigger: React.FC<IReplyTriggerProps> = ({ form, errors, item }) => {
  const { t } = useTranslation();

  const clientData = useSelector(clientDataSelector);

  const platformOptions = normalizeFilterPlatforms(clientData?.platform_types || []);
  const defaultFiltersConfig = useGetDefaultFiltersConfig(t, true);

  const getFilterConfig = (name: string, props = {}) => {
    const filterConfig = defaultFiltersConfig.find(filter => filter.name === name);
    return {
      ...filterConfig,
      props: { ...filterConfig?.props, ...props },
    } as IReplyTriggerComponentProps;
  };

  const hasErrors = useCallback(
    (fieldGroup: keyof typeof fieldGroups) =>
      errors.some(error => fieldGroups[fieldGroup].includes(error)),
    [errors],
  );

  const nextValues = form.getFieldsValue();
  const triggerValues = useMemo(
    () =>
      Object.fromEntries(Object.entries(nextValues).filter(([key]) => triggerFields.includes(key))),
    [nextValues],
  );

  useEffect(() => {
    if (triggerValues) {
      form.setFieldValue("trigger", {
        filters: {
          ...triggerValues,
          message_types: [triggerValues.message_types],
          author_names:
            triggerValues?.author_names?.length > 0 ? triggerValues.author_names : undefined,
        },
      });
    }
  }, [triggerValues, form, item]);

  useEffect(() => {
    if (item.trigger?.filters) {
      Object.values(fieldGroups).forEach(fields => {
        fields.forEach(field => {
          const value = item?.trigger?.filters?.[field as keyof typeof item.trigger.filters];
          if (value) {
            form.setFieldValue([field], field === "message_types" ? (value as string[])[0] : value);
          }
        });
      });
    }
  }, [form, item]);

  return (
    <>
      <Form.Item name="trigger" hidden preserve />
      <ExpansionPanelGroup>
        <ExpansionPanel
          title={t("pages:workflows:form:triggers:reply:step1:title")}
          open={hasErrors("platform_types")}
          data-stonly-target="workflows-reply__trigger_step1"
        >
          <p>{t("pages:workflows:form:triggers:reply:step1:description")}</p>
          <ReplyTriggerComponent
            {...getFilterConfig("platform_types", {
              customprops: { form },
              options: platformOptions,
            })}
            rules={[optionRequiredValidator(t("generic:validation:required"))]}
          />
          <InlineAlert className={s.bbReplyTriggerInlineAlert}>
            {t("pages:workflows:form:triggers:reply:step1:inlineAlert")}
          </InlineAlert>
          <ReplyTriggerComponent
            {...getFilterConfig("asset_ids", {
              placeholder: t("pages:workflows:form:triggers:reply:placeholders:assets"),
            })}
            rules={[optionRequiredValidator(t("generic:validation:required"))]}
          />
          <ReplyTriggerComponent
            {...getFilterConfig("asset_group_ids", {
              placeholder: t("pages:workflows:form:triggers:reply:placeholders:assetGroups"),
            })}
          />
        </ExpansionPanel>

        <ExpansionPanel
          title={t("pages:workflows:form:triggers:reply:step2:title")}
          open={hasErrors("message_types")}
          data-stonly-target="workflows-reply__trigger_step2"
        >
          <p>{t("pages:workflows:form:triggers:reply:step2:description")}</p>
          <ReplyTriggerComponent
            name="message_types"
            label={t("pages:workflows:form:triggers:reply:step2:messageType")}
            rules={[
              {
                required: true,
                validateTrigger: "onChange",
                message: <FormErrorMessage>{t("generic:validation:required")}</FormErrorMessage>,
              },
            ]}
            component={props => (
              <SimpleSelectSingle
                {...props}
                options={messageTypeOptions(t)}
                placeholder={t("pages:workflows:form:triggers:reply:step2:chooseOne")}
              />
            )}
          />
          <InlineAlert className={s.bbReplyTriggerInlineAlert}>
            {t("pages:workflows:form:triggers:reply:step2:inlineAlert")}
          </InlineAlert>
        </ExpansionPanel>

        <ExpansionPanel
          title={t("pages:workflows:form:triggers:reply:step3:title")}
          open={hasErrors("automation_triggers")}
          data-stonly-target="workflows-reply__trigger_step3"
        >
          <p>{t("pages:workflows:form:triggers:reply:step3:description")}</p>
          <ReplyTriggerComponent
            {...getFilterConfig("keyword_query", {
              customprops: { form, name: "keyword_query" },
              placeholder: t("pages:workflows:form:triggers:reply:placeholders:keywords"),
            })}
            rules={[
              {
                required: true,
                message: <FormErrorMessage>{t("generic:validation:required")}</FormErrorMessage>,
              },
            ]}
          />
          <InlineAlert className={s.bbReplyTriggerInlineAlert}>
            {t("pages:workflows:form:triggers:reply:step3:inlineAlert")}
          </InlineAlert>
          <ReplyTriggerComponent
            {...getFilterConfig("sentiments", {
              customprops: { form, name: "sentiments" },
            })}
          />
          <ReplyTriggerComponent
            {...getFilterConfig("languages", {
              customprops: { form, name: "languages" },
            })}
          />
          <ReplyTriggerComponent
            {...getFilterConfig("comment_tags", {
              customprops: { form, name: "comment_tags" },
            })}
          />
          <ReplyTriggerComponent
            {...getFilterConfig("review_types", {
              customprops: { form, name: "review_types" },
            })}
          />
          <ReplyTriggerComponent
            {...getFilterConfig("author_ids", {
              customprops: { form, name: "author_ids" },
            })}
          />
          <ReplyTriggerComponent
            {...getFilterConfig("author_names", {
              customprops: { form, name: "author_names" },
            })}
          />
          <Form.Item
            name="visibility_type"
            initialValue="visible"
            label={t("components:filters:visibility_type:label")}
          >
            <SimpleSelectSingle
              placeholder={t("components:filters:visibility_type:options:visible")}
              disabled
              options={[]}
            />
          </Form.Item>
        </ExpansionPanel>

        <ExpansionPanel
          title={t("pages:workflows:form:triggers:reply:step4:title")}
          optional
          open={hasErrors("additional_filters")}
          data-stonly-target="workflows-reply__trigger_step4"
        >
          <p>{t("pages:workflows:form:triggers:reply:step4:description")}</p>
          <h3 className={s.bbReplyTriggerGroupTitle}>{t("components:filters:groups:posts")}</h3>
          <ReplyTriggerComponent
            {...getFilterConfig("post_types", {
              customprops: { form, name: "post_types" },
            })}
          />
          <ReplyTriggerComponent
            {...getFilterConfig("post_ids", {
              customprops: { form, name: "post_ids" },
            })}
          />
          <ReplyTriggerComponent
            {...getFilterConfig("post_tags", {
              customprops: { form, name: "post_tags" },
            })}
          />
          <ReplyTriggerComponent
            {...getFilterConfig("post_keyword_query", {
              customprops: { form, name: "post_keyword_query" },
            })}
          />
          <h3 className={s.bbReplyTriggerGroupTitle}>{t("components:filters:groups:campaigns")}</h3>
          <ReplyTriggerComponent
            {...getFilterConfig("campaign_ids", {
              customprops: { form, name: "campaign_ids" },
            })}
          />
          <ReplyTriggerComponent
            {...getFilterConfig("adset_ids", {
              customprops: { form, name: "adset_ids" },
            })}
          />
          <ReplyTriggerComponent
            {...getFilterConfig("ad_ids", {
              customprops: { form, name: "ad_ids" },
            })}
          />
          <ReplyTriggerComponent
            {...getFilterConfig("targetings", {
              customprops: { form, name: "targetings" },
            })}
          />
        </ExpansionPanel>
      </ExpansionPanelGroup>
    </>
  );
};
