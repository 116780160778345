import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { addToast, Button, Modal } from "@bbdevcrew/bb_ui_kit_fe";
import WorkflowCreationModal from "@components/control-panel/workflows/creationModal/WorkflowCreationModal"; // eslint-disable-line max-len

import { workflowCreatedSelector } from "@store/workflows/selectors";

import s from "./AlertsTable.module.less";

import { WORKFLOW_TOASTS_DURATION } from "@components/control-panel/workflows/WorkflowsContent";

import { PlusIcon } from "@bbdevcrew/bb_ui_kit_fe";

const EmptyState: React.FC = () => {
  const { t } = useTranslation();

  const [workflowModalOpen, setWorkflowModalOpen] = useState(false);

  const workflowCreated = useSelector(workflowCreatedSelector);

  useEffect(() => {
    if (workflowCreated) {
      setWorkflowModalOpen(false);
      addToast({
        type: "success_accent",
        title: t("pages:workflows:workflowCreationModal:creationSuccess:title"),
        message: t("pages:workflows:workflowCreationModal:creationSuccess:description"),
        duration: WORKFLOW_TOASTS_DURATION,
      });
    }
  }, [workflowCreated, t]);

  return (
    <>
      <div className={s.bbEmptyState}>
        <h2 className={s.bbEmptyStateTitle}>{t("components:listen:alerts:emptyState:title")}</h2>
        <p className={s.bbEmptyStateDescription}>
          {t("components:listen:alerts:emptyState:description")}
        </p>
        <Button
          _size="md"
          _type="primary"
          className={s.bbEmptyStateButton}
          onClick={() => {
            setWorkflowModalOpen(true);
          }}
        >
          <PlusIcon />
          {t("components:listen:alerts:emptyState:buttonText")}
        </Button>
      </div>
      <Modal
        open={workflowModalOpen}
        onCancel={() => setWorkflowModalOpen(false)}
        width={800}
        centered
        noPadding
        responsive
        hideFooter
        hideHeader
      >
        <WorkflowCreationModal workflowType="email_alert" />
      </Modal>
    </>
  );
};

export default EmptyState;
