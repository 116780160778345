import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuDivider,
  DropdownMenuTrigger,
} from "@bbdevcrew/bb_ui_kit_fe";
import { Form } from "antd";
import ManageReportsModal from "../ManageReportsModal";
import DownloadReportModal from "@components/_common/DownloadReportModal";
import ScheduledReportModal from "../ScheduledReportModal";

import {
  getScheduledDocumentsAction,
  postScheduledReportsAction,
  updateScheduledReportsAction,
} from "@store/reports/actions";
import {
  createdScheduledReportSelector,
  pdfReportSelector,
  updatedScheduledReportSelector,
} from "@store/reports/selectors";
import { meSelector } from "@store/me/selectors";

import s from "./ShareReportButton.module.less";

import {
  IScheduledReportPayload,
  ScheduledReportModalType,
} from "../ScheduledReportModal/ScheduledReportModal.types";
import {
  openReportPreview,
  reportSections,
  useShortenedSearchesId,
} from "./ShareReportButton.helpers";
import { IScheduledReport } from "@store/reports/types";
import { IShareReportButtonProps } from "./ShareReportButton.types";
import { addToast } from "@bbdevcrew/bb_ui_kit_fe";
import {
  IShareReportForm,
  ReportSectionType,
} from "@components/_common/DownloadReportModal/DownloadReportModal.types"; // eslint-disable-line max-len

import { DownloadIcon } from "@assets/index";
import { EmailIcon, ExportIcon, LinkIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const ShareReportButton: React.FC<IShareReportButtonProps> = ({ className }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<IShareReportForm>();
  const dispatch = useDispatch();
  const { id: shortenedSearchesId } = useShortenedSearchesId();

  const me = useSelector(meSelector);
  const { inProgress } = useSelector(pdfReportSelector(shortenedSearchesId ?? ""));
  const createdScheduledReport = useSelector(createdScheduledReportSelector);
  const updatedScheduledReport = useSelector(updatedScheduledReportSelector);

  const reportDefaultValue: IScheduledReport = {
    type: "monthly",
    hour: "08:00",
    timezone: me?.timezone || "Europe/London",
    meridiem: "AM",
  };

  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showManageReportsModal, setShowManageReportsModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [editingReport, setEditingReport] = useState(reportDefaultValue);
  const [scheduleReportMode, setScheduleReportMode] = useState<ScheduledReportModalType>("add");

  const getScheduledDocuments = useCallback(
    () => dispatch(getScheduledDocumentsAction()),
    [dispatch],
  );

  const createScheduledReport = useCallback(
    (payload: IScheduledReport) => dispatch(postScheduledReportsAction(payload)),
    [dispatch],
  );

  const updateScheduledReport = useCallback(
    (payload: IScheduledReport) => dispatch(updateScheduledReportsAction(payload)),
    [dispatch],
  );

  useEffect(() => {
    if (showDownloadModal) form.resetFields();
  }, [form, showDownloadModal]);

  useEffect(() => {
    if (createdScheduledReport || updatedScheduledReport) {
      setShowReportModal(false);
      setEditingReport(reportDefaultValue);
      setScheduleReportMode("add");

      if (updatedScheduledReport) getScheduledDocuments();
    }
    // eslint-disable-next-line
  }, [createdScheduledReport, updatedScheduledReport]);

  const onCopyLinkClick = () => {
    navigator.clipboard.writeText(window.location.href);
    addToast({
      type: "success_accent",
      title: t("components:managed:shareReportButton:copied"),
    });
  };

  const downloadReport = ({ name, sections }: IShareReportForm) => {
    if (shortenedSearchesId) {
      const selectedSections = Object.entries(sections)
        .filter(([, value]) => value.checked)
        .map(([key]) => key) as ReportSectionType[];

      openReportPreview({
        path: "managed/transparency/pdf_export",
        id: shortenedSearchesId,
        name,
        sections: selectedSections,
      });
      setShowDownloadModal(false);
    }
  };

  const onCreateOrEditReportFinish = (values: IScheduledReportPayload) => {
    const selectedSections = Object.entries(values.sections)
      .filter(([, value]) => value.checked)
      .map(([key]) => key);

    const payload = {
      ...values,
      sections: selectedSections,
      is_single_email: !!values.is_single_email,
      ui_route_path: "managed/transparency/pdf_export",
    };

    if (values.id) updateScheduledReport(payload);
    else createScheduledReport(payload);
  };

  const onEditReportClick = (report: IScheduledReport) => {
    setEditingReport(report);
    setScheduleReportMode("edit");
    setShowReportModal(true);
  };

  const skipEngage = useMemo(() => {
    const clientPlan = me?.client?.client_plan?.id;
    const isPrime = clientPlan === "PRIME";
    const isGrowthSemi = clientPlan === "GROWTH_SEMI";
    return !isPrime && !isGrowthSemi;
  }, [me]);

  const reportSectionsFiltered = useMemo(() => {
    return skipEngage ? reportSections.filter(section => section !== "bb_engage") : reportSections;
  }, [skipEngage]);

  return (
    <>
      <DropdownMenu
        trigger={({ open }) => (
          <DropdownMenuTrigger
            open={open}
            className={className}
            label={
              <>
                <ExportIcon />
                {t("generic:share")}
              </>
            }
          />
        )}
      >
        {closeMenu => (
          <>
            <DropdownMenuItem
              onClick={() => {
                closeMenu();
                setShowDownloadModal(true);
              }}
            >
              <DownloadIcon /> {t("components:pdfReport:downloadPdf")}
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() => {
                onCopyLinkClick();
                closeMenu();
              }}
            >
              <LinkIcon /> {t("components:managed:shareReportButton:copyLink")}
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() => {
                setShowReportModal(true);
                setEditingReport(reportDefaultValue);
                setScheduleReportMode("add");
                closeMenu();
              }}
            >
              <EmailIcon /> {t("components:managed:shareReportButton:schedule")}
            </DropdownMenuItem>
            <DropdownMenuDivider />
            <DropdownMenuItem
              className={s.bbShareReportButtonManageLink}
              onClick={() => {
                setShowManageReportsModal(true);
                closeMenu();
              }}
            >
              {t("components:managed:shareReportButton:manage")}
            </DropdownMenuItem>
          </>
        )}
      </DropdownMenu>
      <DownloadReportModal
        open={showDownloadModal}
        onCancel={() => setShowDownloadModal(false)}
        onFinish={downloadReport}
        okButtonProps={{ loading: inProgress }}
        sections={reportSectionsFiltered}
      />
      <ManageReportsModal
        open={showManageReportsModal}
        onEditReportClick={onEditReportClick}
        onCancel={() => setShowManageReportsModal(false)}
      />
      <ScheduledReportModal
        open={showReportModal}
        onCancel={() => setShowReportModal(false)}
        onFinish={onCreateOrEditReportFinish}
        initialValues={editingReport}
        mode={scheduleReportMode}
        reportDefaultValue={reportDefaultValue}
      />
    </>
  );
};
