/* eslint-disable max-len */
import React, { ChangeEvent, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import {
  SelectStyled,
  TextareaNew,
  Divider,
  SelectStyledMulti,
  WarningIcon,
  Toggle,
  FormErrorMessage,
  ClearableTrigger,
  SearchInput,
} from "@bbdevcrew/bb_ui_kit_fe";
import { Form } from "antd";
import { SingleMail } from "@components/_common/SingleMail/SingleMail";
import { HumanReview } from "@components/_common/HumanReview/HumanReview";

import { meSelector } from "@store/me/selectors";
import { usersSelector } from "@store/users/selectors";
import { workflowRiskOptionsSelector } from "@store/workflows/selectors";

import s from "./EmailAlertWorkflowAction.module.less";

import { getRiskLevelOptions, ADDITIONAL_TEXT_MAXLEN } from "./EmailAlertWorkflowAction.helpers";
import { FormInstance } from "antd";
import { IClearableTriggerProps, handleMultiselectState } from "@bbdevcrew/bb_ui_kit_fe";
import { IWorkflowEmailAlertAction } from "@store/workflows/types";

import { EmailIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface IEmailAlertWorkflowAction {
  form: FormInstance;
  actionParent?: string;
  actionParentIndex?: number;
  actionParentType?: "single" | "multiple";
}

export const EmailAlertWorkflowAction: React.FC<IEmailAlertWorkflowAction> = ({
  form,
  actionParentIndex = 0,
  actionParent = "action",
  actionParentType = "single",
}) => {
  const { t } = useTranslation();
  const isActionParentMulti = actionParentType === "multiple";
  const actionParentValue = form.getFieldValue(actionParent) || (isActionParentMulti ? [] : {});
  const values = (actionParentValue || {}) as IWorkflowEmailAlertAction;
  const valuesMulti = ((actionParentValue || []) as IWorkflowEmailAlertAction[])[actionParentIndex];
  const currentValues = isActionParentMulti ? valuesMulti : values;
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const users = useSelector(usersSelector);
  const me = useSelector(meSelector);
  const workflowRiskOptions = useSelector(workflowRiskOptionsSelector);

  const isManagedClient = !!me?.client?.is_managed;
  const notificationCanBeManaged = !!me?.is_notification_can_be_managed;
  const hasRiskIdentification = currentValues?.risk_identification?.risked;

  const usersOptions = users.map(user => ({
    id: user.id,
    value: user.id,
    label: user.email,
  }));

  const getMultiValue = (key: string, value: unknown) => {
    return [
      // copying elements before the target index
      ...actionParentValue.slice(0, actionParentIndex),
      {
        ...actionParentValue[actionParentIndex],
        [key]: value,
      },
      // copying elements after the target index
      ...actionParentValue.slice(actionParentIndex + 1),
    ];
  };

  const onToggleChange = (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;

    form.setFieldsValue({
      [actionParent]: isActionParentMulti
        ? getMultiValue(name, checked)
        : {
            [name]: checked,
          },
    });
  };

  const onImpactAreaChange = (val: string) => {
    const impactAreaValue = values.risk_identification?.impact_area;
    const newValue = impactAreaValue?.includes(val)
      ? impactAreaValue?.filter(i => i !== val)
      : [...(impactAreaValue || []), val];

    form.setFieldsValue({
      [actionParent]: isActionParentMulti
        ? getMultiValue("risk_identification", {
            ...actionParentValue[actionParentIndex].risk_identification,
            impact_area: newValue?.length ? newValue : undefined,
          })
        : {
            risk_identification: {
              impact_area: newValue?.length ? newValue : undefined,
            },
          },
    });
  };

  const onRiskSectionChange = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;

    if (checked) {
      form.setFieldsValue({
        [actionParent]: isActionParentMulti
          ? getMultiValue("risk_identification", {
              ...actionParentValue[actionParentIndex]?.risk_identification,
              risked: checked,
              risk_lvl: "low",
            })
          : {
              risk_identification: {
                risked: checked,
                risk_lvl: "low",
              },
            },
      });
    } else {
      form.setFieldsValue({
        [actionParent]: isActionParentMulti
          ? getMultiValue("risk_identification", undefined)
          : {
              risk_identification: undefined,
            },
      });
    }
  };

  const onInternalChangeUsers = (selectedValue: string) => {
    const newState = handleMultiselectState(usersOptions, selectedUsers, selectedValue);

    setSelectedUsers(newState);
    form.setFieldValue(
      isActionParentMulti
        ? [actionParent, actionParentIndex, "user_ids"]
        : [actionParent, "user_ids"],
      newState,
    );
  };

  const TriggerWithTooltip = (tooltipProps: IClearableTriggerProps) => {
    const impactAreaValue = currentValues?.risk_identification?.impact_area;

    if (!impactAreaValue?.length) {
      return ClearableTrigger(tooltipProps);
    }

    const tooltipElement = workflowRiskOptions?.impact_area
      ?.filter(opt => impactAreaValue?.includes(opt.id))
      .map(group => (
        <span className={s.bbDisplayBlock} key={group.id}>
          {group.label}
        </span>
      ));

    return ClearableTrigger({
      tooltip: tooltipElement,
      onClear: () => {
        form.setFieldsValue({
          [actionParent]: isActionParentMulti
            ? getMultiValue("risk_identification", {
                ...actionParentValue[actionParentIndex]?.risk_identification,
                impact_area: undefined,
              })
            : {
                risk_identification: {
                  impact_area: undefined,
                },
              },
        });
      },
      ...tooltipProps,
    });
  };

  const UsersTriggerWithTooltip = (tooltipProps: IClearableTriggerProps) => {
    if (!selectedUsers?.length) return ClearableTrigger(tooltipProps);

    const tooltipElement = usersOptions
      ?.filter(opt => selectedUsers?.includes(opt.id))
      .map(group => (
        <span className={s.bbDisplayBlock} key={group.id}>
          {group.label}
        </span>
      ));

    return ClearableTrigger({
      tooltip: tooltipElement,
      onClear: () => form.setFieldValue("user_ids", []),
      ...tooltipProps,
    });
  };

  return (
    <div className={s.bbEmailAlertAction}>
      <div className={s.bbEmailAlertActionConfig}>
        <div className={s.bbEmailAlertActionForm}>
          <div className={s.bbEmailAlertActionSection}>
            <div>
              <div className={s.bbEmailAlertActionSectionIcon}>
                <EmailIcon />
              </div>
              <span> {t("pages:workflows:form:actions:email_alert:tabsLabel")}</span>
            </div>
          </div>
          <Form.Item
            name={
              isActionParentMulti
                ? [actionParent, actionParentIndex, "user_ids"]
                : [actionParent, "user_ids"]
            }
            className={s.bbFormItemUsers}
            label={t("components:notificationsPanel:modals:create:form:users:label")}
            rules={[
              {
                required: true,
                message: (
                  <FormErrorMessage>
                    {t("components:notificationsPanel:modals:create:form:users:message")}
                  </FormErrorMessage>
                ),
              },
            ]}
          >
            <SelectStyledMulti
              useDynamicPlacement
              dropdownMatchSelectWidth
              tabIndex={-1}
              _size="sm"
              placeholder={t("generic:search")}
              data-cy="email-alert-subscribers-select"
              options={usersOptions}
              className={s.bbSubscriberSelect}
              TriggerVariation={UsersTriggerWithTooltip}
              value={selectedUsers}
              onChange={onInternalChangeUsers}
              highlightTerm={searchTerm}
              DropdownHeader={
                <SearchInput searchTerm={searchTerm} handleSetSearchTerm={setSearchTerm} />
              }
            />
          </Form.Item>
          <Form.Item
            name={
              isActionParentMulti
                ? [actionParent, actionParentIndex, "is_single_email"]
                : [actionParent, "is_single_email"]
            }
          >
            <SingleMail
              name="is_single_email"
              checked={!!currentValues?.is_single_email}
              onChange={onToggleChange("is_single_email")}
            />
          </Form.Item>
          {isManagedClient && notificationCanBeManaged && (
            <div className={s.bbHumanReviewCheck}>
              <Form.Item
                name={
                  isActionParentMulti
                    ? [actionParent, actionParentIndex, "is_managed"]
                    : [actionParent, "is_managed"]
                }
              >
                <HumanReview
                  checked={!!currentValues?.is_managed}
                  onChange={onToggleChange("is_managed")}
                />
              </Form.Item>
            </div>
          )}
          <Divider className={s.bbDivider} />
          <div
            className={classNames(s.bbRiskSection, {
              [s.bbRiskSectionHidden]: !hasRiskIdentification,
            })}
          >
            <div className={s.bbEmailAlertActionSection}>
              <div>
                <div className={s.bbEmailAlertActionSectionIcon}>
                  <WarningIcon />
                </div>
                <span>
                  {t("pages:workflows:form:actions:email_alert:risk_identification:potentialRisk")}
                </span>
              </div>
              <Toggle
                id="risk_identification"
                checked={hasRiskIdentification}
                onChange={onRiskSectionChange}
              />
            </div>
            {hasRiskIdentification && (
              <>
                <div className={s.bbRiskLevelSelect}>
                  <Form.Item
                    required
                    name={
                      isActionParentMulti
                        ? [actionParent, actionParentIndex, "risk_identification", "risk_lvl"]
                        : [actionParent, "risk_identification", "risk_lvl"]
                    }
                    label={t(
                      "pages:workflows:form:actions:email_alert:risk_identification:riskLevel",
                    )}
                  >
                    <SelectStyled
                      options={getRiskLevelOptions(workflowRiskOptions?.risk_lvl)}
                      prefixEl={
                        getRiskLevelOptions(workflowRiskOptions?.risk_lvl).find(
                          i => i.id === currentValues?.risk_identification?.risk_lvl,
                        )?.leftLabel
                      }
                      dropdownMenuClassName={s.bbDropdownMenu}
                      wrapperClassName={s.bbRiskLevelSelectWrapper}
                      dropdownMatchSelectWidth
                    />
                  </Form.Item>

                  <Form.Item
                    name={
                      isActionParentMulti
                        ? [actionParent, actionParentIndex, "risk_identification", "impact_area"]
                        : [actionParent, "risk_identification", "impact_area"]
                    }
                    label={t(
                      "pages:workflows:form:actions:email_alert:risk_identification:impactArea",
                    )}
                  >
                    <SelectStyledMulti
                      options={workflowRiskOptions?.impact_area}
                      dropdownMenuClassName={s.bbDropdownMenu}
                      TriggerVariation={TriggerWithTooltip}
                      placeholder={t("generic:all")}
                      onChange={onImpactAreaChange}
                      dropdownMatchSelectWidth
                    />
                  </Form.Item>
                </div>
                <Form.Item
                  name={
                    isActionParentMulti
                      ? [
                          actionParent,
                          actionParentIndex,
                          "risk_identification",
                          "additional_instructions",
                        ]
                      : [actionParent, "risk_identification", "additional_instructions"]
                  }
                  label={t(
                    "pages:workflows:form:actions:email_alert:risk_identification:additionalInstructions",
                  )}
                  className={s.bbEmailAlertTextAreaForm}
                  rules={[
                    {
                      max: ADDITIONAL_TEXT_MAXLEN,
                      message: <FormErrorMessage>{t("generic:validation:max")}</FormErrorMessage>,
                    },
                  ]}
                >
                  <TextareaNew
                    className={s.bbEmailAlertTextArea}
                    maxLength={ADDITIONAL_TEXT_MAXLEN}
                  />
                </Form.Item>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
