import classNames from "classnames";
import { useTranslation } from "react-i18next";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Form } from "antd";
import ExpansionPanel, { ExpansionPanelBadge } from "../../ExpansionPanel";
import { Filters, Button, IFormFilterRequest, Toggle } from "@bbdevcrew/bb_ui_kit_fe";

import { clientDataSelector } from "@store/me/selectors";
import { getAutocompleteOptionsAction } from "@store/autocomplete/actions";
import { autocompleteOptionsSelector } from "@store/autocomplete/selectors";

import s from "./AllFiltersWorkflowTrigger.module.less";

import {
  debounce,
  IFilters,
  IFormHandler,
  IGetAutocompleteOptionsPayload,
} from "@bbdevcrew/bb_ui_kit_fe";
import {
  filterHasNonEmptyValue,
  useWorkflowTriggerFilters,
} from "./AllFiltersWorkflowTrigger.helpers";
import { getFetchValuesFieldName } from "@utils/filters";
import { IAllFiltersWorkflowTrigger, IFiltersPanelProps } from "./AllFiltersWorkflowTrigger.types";

import { ChevronDownIcon, ChevronUpIcon } from "@bbdevcrew/bb_ui_kit_fe";

const FiltersPanel: FC<IFiltersPanelProps> = ({
  title,
  value,
  open,
  setOpen,
  filtersRef,
  headerSuffix,
  hiddenFilters = [],
  hideCareReviewTag = false,
  hiddenPlatformTypes = [],
  onInternalChange,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [filtersForm] = Form.useForm<IFormFilterRequest>();
  const { workflowTriggerFilters } = useWorkflowTriggerFilters(
    value,
    hideCareReviewTag,
    hiddenFilters,
    hiddenPlatformTypes,
  );

  const [fullHeight, setFullHeight] = useState(false);

  const clientData = useSelector(clientDataSelector);
  const autocompleteOptions = useSelector(autocompleteOptionsSelector);

  // eslint-disable-next-line
  const getAutocompleteOptions = useCallback(
    debounce((payload: IGetAutocompleteOptionsPayload) => {
      dispatch(getAutocompleteOptionsAction(payload));
    }, 300),
    [dispatch],
  );

  useEffect(() => {
    if (filtersRef?.current && value) {
      filtersRef.current.initializeFormValues(value);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <ExpansionPanel
      title={title}
      open={open}
      onToggle={() => setOpen(!open)}
      headerSuffix={headerSuffix}
    >
      <Filters
        ref={filtersRef}
        form={filtersForm}
        onChangeCallback={onInternalChange}
        filtersConfig={workflowTriggerFilters}
        onFilter={() => {}} // eslint-disable-line
        clientPlatformTypes={clientData?.platform_types || []}
        className={classNames(s.bbAppFiltersFormWrapper, {
          [s.bbAppFiltersFormWrapperFullHeight]: fullHeight,
        })}
        autocompleteOptions={autocompleteOptions}
        getAutocompleteOptions={getAutocompleteOptions}
        sanitizeFieldName={getFetchValuesFieldName}
        disableExclusionOptions
      />
      <Button
        className={s.bbViewMoreButton}
        _type="link"
        onClick={() => setFullHeight(!fullHeight)}
      >
        {t(`pages:workflows:form:triggers:email_alert:${fullHeight ? "viewLess" : "viewMore"}`)}
        {fullHeight ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </Button>
    </ExpansionPanel>
  );
};

export const AllFiltersWorkflowTrigger: FC<IAllFiltersWorkflowTrigger> = ({
  title,
  form,
  hiddenFilters,
  hiddenPlatformTypes,
}) => {
  const { t } = useTranslation();
  const filtersRef = useRef<IFormHandler>(null);
  const exclusionFiltersRef = useRef<IFormHandler>(null);
  const value = form.getFieldValue("trigger")?.filters || {};
  const excludedValues =
    form.getFieldValue("trigger")?.excluded ||
    form.getFieldValue("trigger")?.filters?.exclusion_filters ||
    {};
  const initialExludedValuesExist = filterHasNonEmptyValue(
    form.getFieldValue("trigger")?.filters?.exclusion_filters,
  );

  const [isFiltersPanelOpen, setIsFiltersPanelOpen] = useState(true);
  const [isExclusionPanelOpen, setIsExclusionPanelOpen] = useState(initialExludedValuesExist);
  const [isExlusionToggleChecked, setIsExclusionToggleChecked] =
    useState(initialExludedValuesExist);

  const onInternalChange = (type: "filters" | "excluded") => (filters: IFilters) => {
    const currentValue = form.getFieldValue("trigger") || {};

    form.setFields([
      {
        name: ["trigger", "filters"],
        value:
          type === "filters"
            ? filters
            : {
                ...currentValue.filters,
                exclusion_filters: filters,
              },
      },
    ]);
  };

  const onChangeExclusionToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isToggleChecked = e.target.checked;
    setIsExclusionToggleChecked(isToggleChecked);

    const currentValue = form.getFieldValue("trigger") || {};

    if (!isToggleChecked) {
      form.setFields([
        {
          name: ["trigger", "filters"],
          value: {
            ...currentValue.filters,
            exclusion_filters: {},
          },
        },
      ]);

      exclusionFiltersRef.current?.resetFields();
      setIsExclusionPanelOpen(false);
    } else setIsExclusionPanelOpen(true);
  };

  return (
    <div className={s.bbWorkflowEmailFilters}>
      {!!title && <div className={s.bbWorkflowEmailFiltersTitle}>{title}</div>}
      <FiltersPanel
        hideCareReviewTag
        filtersRef={filtersRef}
        title={
          <>
            <ExpansionPanelBadge>
              {t("components:filters:workflows:filters:badge")}
            </ExpansionPanelBadge>
            {t("components:filters:workflows:filters:title")}
          </>
        }
        value={value}
        onInternalChange={onInternalChange("filters")}
        open={isFiltersPanelOpen}
        setOpen={setIsFiltersPanelOpen}
        hiddenFilters={hiddenFilters}
        hiddenPlatformTypes={hiddenPlatformTypes}
      />
      <FiltersPanel
        hideCareReviewTag
        filtersRef={exclusionFiltersRef}
        title={
          <>
            <ExpansionPanelBadge danger>
              {t("components:filters:workflows:excluded:badge")}
            </ExpansionPanelBadge>
            {t("components:filters:workflows:excluded:title")}
          </>
        }
        headerSuffix={
          <Toggle
            id="exclusion_header_toggle"
            checked={isExlusionToggleChecked}
            onChange={onChangeExclusionToggle}
          />
        }
        value={excludedValues}
        onInternalChange={onInternalChange("excluded")}
        open={isExclusionPanelOpen}
        setOpen={setIsExclusionPanelOpen}
        hiddenFilters={hiddenFilters}
        hiddenPlatformTypes={hiddenPlatformTypes}
      />
    </div>
  );
};
