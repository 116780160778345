import dayjs from "dayjs";
import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  workflowCategoriesSelector,
  workflowCreatingSelector,
  workflowEditingSelector,
  workflowItemsSelector,
} from "@store/workflows/selectors";
import { createWorkflowAction, editWorkflowAction } from "@store/workflows/actions";

import s from "./Workflows.module.less";

import { t } from "../../../App";

import {
  IWorkflowCategory,
  IWorkflowItem,
  WorkflowItemCategoryType,
  IWorkflowAction,
  IWorkflowTrigger,
  IWorkflowItemPayload,
  IWorkflowEmailAlertAction,
  WorkflowGroupsAndCategoriesType,
  IWorkflowReplyAction,
} from "@store/workflows/types";
import {
  ISimpleTableColumn,
  WorkflowIcon,
  PositiveIcon,
  TagIcon,
  ZendeskIcon,
  ReplyIcon,
  ArchiveIcon,
  AssignCommentIcon,
  EyeOffFilledIcon,
  ChipType,
  AlertTriangleIcon,
  ArrowCornerLeftUpIcon,
} from "@bbdevcrew/bb_ui_kit_fe";

export const workflowGroupsAndCategories: WorkflowGroupsAndCategoriesType[] = [
  "all",
  "organize",
  "analyze",
  "protect",
  "tag",
  "archive",
  "assign",
  "sentiment",
  "hide",
  "email_alert",
  "zendesk",
  "engage",
  "reply",
];

function getWorkflowsCategoryIdMap(items: IWorkflowCategory[]) {
  return items.reduce((acc: WorkflowsCategoryIdMap, current) => {
    const { id, ids, sub_categories } = current;
    acc[id] = ids;
    acc = {
      ...acc,
      [id]: ids,
      ...(sub_categories ? getWorkflowsCategoryIdMap(sub_categories) : {}),
    };
    return acc;
  }, {});
}

export function useWorkflows() {
  const [searchParams, setSearchParams] = useSearchParams();

  const items = useSelector(workflowItemsSelector);
  const categories = useSelector(workflowCategoriesSelector);

  const categoryIdMap = useMemo(() => getWorkflowsCategoryIdMap(categories), [categories]);
  const category = searchParams.get("category") || "";
  const activeCategory = useMemo(() => {
    return workflowGroupsAndCategories.includes(category as WorkflowGroupsAndCategoriesType)
      ? category
      : "all";
  }, [category]);

  return {
    items,
    categories,
    activeCategory,
    setCategory(id: string) {
      setSearchParams({
        ...Object.fromEntries(new URL(window.location.href).searchParams),
        category: id,
      });
    },
    activeCategoryIds: categoryIdMap[activeCategory],
    categoryIdMap,
  };
}

/* eslint-disable */
const omitField = (obj: any, field: string) => {
  const { [field]: _, ...rest } = obj;
  return rest;
};
/* eslint-enable */

function getTriggerActionMap({
  category,
  trigger,
  action,
  additional_actions,
}: {
  category: WorkflowItemCategoryType;
  trigger?: IWorkflowTrigger;
  action?: IWorkflowAction;
  additional_actions?: IWorkflowAction[];
}) {
  let replyAction = undefined;

  if (category === "reply") {
    action = action as IWorkflowReplyAction;

    const privateReplyTemplate = !!action?.private_reply_template_id;
    const publicReplyTemplate = !!action?.reply_template_id;

    replyAction = {
      ...action,
      reply_types:
        privateReplyTemplate && publicReplyTemplate
          ? ["public", "private"]
          : privateReplyTemplate && !publicReplyTemplate
            ? ["private"]
            : ["public"],
      reply_template_id: action?.reply_template_id
        ? parseFloat(action.reply_template_id)
        : undefined,
      private_reply_template_id: action?.private_reply_template_id
        ? parseFloat(action.private_reply_template_id)
        : undefined,
    };
  }

  if (category === "hide" && (trigger?.filters?.start_time || trigger?.filters?.end_time)) {
    trigger = {
      ...trigger,
      filters: {
        ...trigger?.filters,
        ...(trigger?.filters?.start_time && {
          start_time: dayjs(trigger?.filters?.start_time).format("YYYY-MM-DDTHH:mm:ss"),
        }),
        ...(trigger?.filters?.end_time && {
          end_time: dayjs(trigger?.filters?.end_time).format("YYYY-MM-DDTHH:mm:ss"),
        }),
      },
    };
  }

  if (
    category === "tag" &&
    trigger?.filters?.exclusion_filters &&
    trigger?.filters?.exclusion_filters?.asset_ids
  ) {
    const { asset_ids: assetIds, ...exclusionFilters } = trigger?.filters?.exclusion_filters;

    trigger = {
      ...trigger,
      filters: {
        ...trigger?.filters,
        exclusion_filters: {
          ...exclusionFilters,
          ...(assetIds.length > 0 && { asset_ids: assetIds }),
        },
      },
    };
  }

  return {
    sentiment: {
      sentiment: action,
      trigger: {
        filters: {
          keyword_query: trigger?.keyword_query,
          keyword_query_in_translation: false,
        },
      },
    },
    tag: {
      tags: action,
      trigger: {
        filters: {
          ...trigger?.filters,
          keyword_query: trigger?.filters?.keyword_query,
          keyword_query_in_translation: false,
          time_frame: trigger?.time_frame,
        },
      },
    },
    archive: {
      trigger: {
        filters: {
          keyword_query: trigger?.keyword_query,
          keyword_query_in_translation: false,
        },
      },
    },
    hide: {
      additional_actions: additional_actions?.length
        ? {
            items: additional_actions?.map(additional_action => {
              return {
                category: "email_alert",
                email_alert: omitField(additional_action, "category"),
              };
            }),
          }
        : undefined,
      trigger,
    },
    email_alert: {
      trigger,
      email_alert: action,
    },
    assign: {},
    zendesk: {
      trigger,
      zendesk: action,
    },
    reply: {
      trigger,
      reply: replyAction,
    },
  }[category];
}

export const useWorkflowSubmitHandler = () => {
  const dispatch = useDispatch();

  const isCreating = useSelector(workflowCreatingSelector);
  const isEditing = useSelector(workflowEditingSelector);

  const isLoading = isCreating || isEditing;

  const handleSubmit = useCallback(
    (workflow: IWorkflowItem) => {
      const {
        id,
        title,
        description,
        category,
        enabled,
        trigger,
        action,
        additional_actions,
        visibility_access,
        editability_access,
      } = workflow;

      if (category === "email_alert") {
        (action as IWorkflowEmailAlertAction).is_post_based = trigger?.filters?.is_post_based;
        delete trigger?.filters?.is_post_based;
      }

      const payload = {
        title,
        description,
        category,
        enabled,
        trigger,
        visibility_access,
        editability_access,
        ...getTriggerActionMap({ category, trigger, action, additional_actions }),
      } as IWorkflowItemPayload;

      if (id) {
        dispatch(
          editWorkflowAction({
            id,
            ...payload,
          }),
        );
      } else {
        dispatch(createWorkflowAction(payload));
      }
    },
    [dispatch],
  );

  return {
    isLoading,
    handleSubmit,
  };
};

type WorkflowsCategoryIdMap = {
  [key: string]: string[];
};

export const defaultValues: IWorkflowItem = {
  id: "",
  category: "sentiment",
  is_editable: true,
  title: "",
  description: "",
  example: "",
  enabled: true,
  show_enable_checkbox: true,
  trigger: undefined,
  action: undefined,
};

export const getWorkflowItemTitle = (category: WorkflowItemCategoryType) => {
  return category?.replace(/_/g, " ").toUpperCase();
};

export const getTableColumns = (isMobile: boolean): ISimpleTableColumn[] => {
  return [
    {
      name: t(""),
      id_name: "enabled",
      colSpan: isMobile ? 4 : 2,
      isSortable: true,
      className: s.bbTableCol,
    },
    {
      name: t("pages:workflows:tableColumns:action"),
      id_name: "category",
      colSpan: isMobile ? 8 : 4,
      isSortable: true,
      className: s.bbTableCol,
      prefix: (
        <i className={s.bbTableColIcon}>
          <ArrowCornerLeftUpIcon />
        </i>
      ),
    },
    {
      name: t("pages:workflows:tableColumns:workflow"),
      id_name: "title",
      colSpan: isMobile ? 12 : 18,
      isSortable: true,
      className: s.bbTableCol,
      prefix: (
        <i className={s.bbTableColIcon}>
          <WorkflowIcon />
        </i>
      ),
    },
  ];
};

export const WORKFLOW_CHIP_MAP: Record<
  WorkflowItemCategoryType,
  { type: ChipType; icon: string | JSX.Element }
> = {
  sentiment: {
    type: "success",
    icon: PositiveIcon,
  },
  tag: {
    type: "lilac",
    icon: TagIcon,
  },
  archive: {
    type: "mint",
    icon: ArchiveIcon,
  },
  hide: {
    type: "risk-high",
    icon: EyeOffFilledIcon,
  },
  email_alert: {
    type: "risk-moderate",
    icon: AlertTriangleIcon,
  },
  assign: {
    type: "grey",
    icon: AssignCommentIcon,
  },
  zendesk: {
    type: "slate",
    icon: ZendeskIcon,
  },
  reply: {
    type: "azure",
    icon: ReplyIcon,
  },
};
