import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

import Overview from "./overview";
import Workflows from "./workflows";
import Transparency from "@components/managed/Transparency";

import { getAllModulesSectionsSelector } from "@store/modules/selectors";

import { IAvailableSectionsType, IControlPanelModuleFeatureType } from "@store/modules/types";
import { getCurrentModuleName } from "@utils/paths";

const featureMap: Record<IControlPanelModuleFeatureType, JSX.Element> = {
  "brandbastion_actions-transparency_dashboard": <Transparency />,
  "control_panel-workflows": <Workflows />,
  "control_panel-overview": <Overview />,
};

const ControlPanel: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const sectionId = searchParams.get("section") as IControlPanelModuleFeatureType;

  useEffect(() => {
    if (!sectionId) {
      setSearchParams({ section: "control_panel-overview" });
    }
  }, [sectionId, setSearchParams]);

  const moduleId = getCurrentModuleName();

  const modulesSections = useSelector(getAllModulesSectionsSelector);

  const sections = modulesSections[moduleId as IAvailableSectionsType];
  const nonAvailableFeatures = sections
    ?.flatMap(section => section.features || [])
    .filter(feature => !feature.is_visible || feature.is_locked)
    .map(feature => feature.id);

  const isFeatureAvailable = (featureName: IControlPanelModuleFeatureType) =>
    !nonAvailableFeatures?.includes(featureName);

  return (
    <>
      {Object.keys(featureMap).map(key =>
        isFeatureAvailable(key as IControlPanelModuleFeatureType) && sectionId === key
          ? featureMap[key as IControlPanelModuleFeatureType]
          : null,
      )}
    </>
  );
};

export default ControlPanel;
